import React from 'react';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { carScheduleIcon, homeScheduleIcon } from './asset';

interface ScheduleData {
  day: string;
  date: number;
  value: number | null;
  catalougeType : string
}

interface Props {
    scheduleData : ScheduleData[]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'linear-gradient(92.64deg, #7A36BA 0%, #3173E1 114.09%)',
    padding: theme.spacing(4),
    borderRadius: '16px',
    color: 'white',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    width: "100px",
    marginBottom: theme.spacing(2),
  },
  bar: {
    height: '30px',
    background: `linear-gradient(90deg, #FFFFFF -2.38%, rgba(255, 255, 255, 0) 97.62%)`,
    display: 'flex',
    alignItems: 'center',
    color: '#23395D',
    justifyContent: "center"
  },
  dayText: {
    color: 'rgba(255,255,255,0.8)',
  },
  iconStyle : {
    height: 45,
    width: 45
  },
  innerBox : {
    height: 150,
    position: "relative"
  },
  homeBox : {
    position: "absolute",
    top: 0,
    width: 100
  },
  carBox : {
    position: "absolute",
    top: 65,
    width: 100
  },
  scrollContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: "hidden",
    whiteSpace: 'nowrap',  // Keep items in a single row,
    scrollbarWidth: "thin"
  },
  labelText: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      color: "#23395D"
  },
  dayTxt: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: "#E7E7E7"
},
dateTxt: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    color: "#E7E7E7"
},
}));  

const Schedule: React.FC<Props> = ({ scheduleData }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={3}>
          <Box className={classes.scrollContainer}>
              <Grid container spacing={2} wrap='nowrap'>
                  {/* Icons */}
                  <Grid item xs={1}>
                      <Box className={classes.iconContainer}>
                          <img src={homeScheduleIcon} className={classes.iconStyle} />
                      </Box>
                      <br />
                      <Box className={classes.iconContainer}>
                          <img src={carScheduleIcon} className={classes.iconStyle} />
                      </Box>
                  </Grid>

                  {/* Date and Bar Layout */}
                  {scheduleData.map((item, index) => (
                      <Grid item xs key={index} className={classes.dateContainer} style={{ padding:"0 30px"}}>
                          <Box className={classes.innerBox} >
                              {item.value !== null && (
                                  <Box className={`${classes.bar} ${item.catalougeType === "Home" ? classes.homeBox : classes.carBox}`}>
                                      <Typography variant='h4' className={classes.labelText}>+{item.value}</Typography>
                                  </Box>
                              )}
                          </Box>
                          <Typography className={classes.dayTxt}>
                              {item.day}
                          </Typography>
                          <Typography className={classes.dateTxt}>{item.date}</Typography>
                            <br />
                      </Grid>
                  ))}
              </Grid>
          </Box>
    </Paper>
  );
};

export default Schedule;
