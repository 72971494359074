// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography,
    Button,
    Grid,
    OutlinedInput
} from "@material-ui/core"

import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";
import PricingCard from "../../../components/src/PricingCard.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { editFileIcon } from "./assets";

import GuestInstructionController, {
    Props,
  } from "./GuestInstructionController";

class GuestInstruction extends GuestInstructionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderCheckInCheckOutCard = () => {
        return (
            this.state.editInstruction.checkInInfo ?
                <>


                    {this.state.checkInInstructionArray.map((arrVal, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{arrVal.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {arrVal.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            data-test-id={"handleCheckInput"}
                                            value={item.description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyCheckInput"} onClick={this.appyCheckInSubInstruction}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.checkInDateTimeText}</Typography>
                            <img src={editFileIcon} data-test-id={"editCheckInput"} onClick={() => this.handleEditClick("checkInInfo")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.checkInInfoDetails.map(item =>
                            <Box className="flexDiv" key={item.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{item.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderHouseRulesCard = () => {
        return (
            this.state.editInstruction.houseRules ?
                <>
                    {this.state.houseRulesInstructionArray.map((houseRulesVal, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{houseRulesVal.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {houseRulesVal.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            disabled={this.handleViewOnly(index)}
                                            readOnly={this.handleViewOnly(index)}
                                            value={item.description}
                                            data-test-id={"changeRuleInput"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleHouseRuleInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"addNewRuleInput"}
                                        onClick={() => this.addNewSection(this.state.houseRulesInstructionArray, "houseRulesInstructionArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyRuleInput"} onClick={this.applyHouseRoleSubInstruction}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.houseRuleText}</Typography>
                            <img src={editFileIcon} data-test-id={"editRuleInput"} onClick={() => this.handleEditClick("houseRules")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.houseRulesDetails.map(houseRule =>
                            <Box className="flexDiv" key={houseRule.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{houseRule.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderLocationDetailsCard = () => {
        return (
            this.state.editInstruction.locationDetails ?
                <>


                    {this.state.locationInstructionArray.map((arrValue, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{arrValue.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {arrValue.sections_attributes.map((locationAttr, index) =>
                                    <Box className="inputBox" key={`attributes-${locationAttr}`}>
                                        <Typography className="titleText">{locationAttr.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            data-test-id="locationHandleChange"
                                            value={locationAttr.description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleLocationInputChange(event, index)}
                                            fullWidth
                                        />
                                    </Box>
                                )}

                                <Box>
                                    <Button
                                        fullWidth
                                        className="addBtn"
                                        endIcon={<AddCircleOutlineIcon />}
                                        data-test-id="locationAddNew"
                                        onClick={() => this.addNewSection(this.state.locationInstructionArray, "locationInstructionArray")}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id="locationApplyBtn" onClick={this.applyLocationSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.locationDetailstext}</Typography>
                            <img src={editFileIcon} data-test-id="locationEdit" onClick={() => this.handleEditClick("locationDetails")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.locationDetails.map(location =>
                            <Box className="flexDiv" key={location.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{location.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderAccessMethodCard = () => {
        return (
            this.state.editInstruction.accessMethod ?
                <>


                    {this.state.accessMethodInstructionArray.map((arrValue, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{arrValue.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {arrValue.sections_attributes.map((accessMethodAttr, index) =>
                                    <Box className="inputBox" key={`attributes-${accessMethodAttr}`}>
                                        <Typography className="titleText">{accessMethodAttr.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            value={accessMethodAttr.description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAccessMethodInputChange(event, index)}
                                            fullWidth
                                            data-test-id="accMethodChange"
                                        />
                                    </Box>
                                )}

                                <Box>
                                    <Button
                                        fullWidth
                                        className="addBtn"
                                        endIcon={<AddCircleOutlineIcon />}
                                        data-test-id="accMethodAddSection"
                                        onClick={() => this.addNewSection(this.state.accessMethodInstructionArray, "accessMethodInstructionArray")}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id="accMethodApply" onClick={this.applyAccessMethodSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.accessMethodText}</Typography>
                            <img src={editFileIcon} data-test-id="accMethodEdit" onClick={() => this.handleEditClick("accessMethod")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.accessMethodDetails.map(accMethod =>
                            <Box className="flexDiv" key={accMethod.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{accMethod.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderParkingInfoCard = () => {
        return (
            this.state.editInstruction.parkingInfo ?
                <>


                    {this.state.parkingInstructionArray.map((arrValue, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{arrValue.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {arrValue.sections_attributes.map((parkinginfo, index) =>
                                    <Box className="inputBox" key={`attributes-${parkinginfo}`}>
                                        <Typography className="titleText">{parkinginfo.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            value={parkinginfo.description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleParkingInputChange(event, index)}
                                            fullWidth
                                            data-test-id="parkingChange"
                                        />
                                    </Box>
                                )}

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id="parkingApply" onClick={this.applyParkingSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.partkingInstructionText}</Typography>
                            <img src={editFileIcon} data-test-id="parkingEdit" onClick={() => this.handleEditClick("parkingInfo")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.parkingDetails.map(parkinArr =>
                            <Box className="flexDiv" key={parkinArr.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{parkinArr.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderWifiInfoCard = () => {
        return (
            this.state.editInstruction.wifiInfo ?
                <>


                    {this.state.wifiInstructionArray.map((arrValue, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{arrValue.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {arrValue.sections_attributes.map((wifiArr, index) =>
                                    <Box className="inputBox" key={`attributes-${wifiArr}`}>
                                        <Typography className="titleText">{wifiArr.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            value={wifiArr.description}
                                            data-test-id="wifiChange"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleWifiInputChange(event, index)}
                                            fullWidth
                                        />
                                    </Box>
                                )}

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id="wifiApply" onClick={this.applyWifiSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.wifiInstructionText}</Typography>
                            <img src={editFileIcon} data-test-id="wifiEdit" onClick={() => this.handleEditClick("wifiInfo")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.wifiDetails.map(wifiInfo =>
                            <Box className="flexDiv" key={wifiInfo.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{wifiInfo.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderOtherPropertyCard = () => {
        return (
            this.state.editInstruction.otherDetails ?
                <>
                    {this.state.otherPropertyDetailsArray.map((otherVal, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{otherVal.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {otherVal.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            value={item.description}
                                            data-test-id={"otherChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOtherPropertyInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"otherAddNew"}
                                        onClick={() => this.addNewSection(this.state.otherPropertyDetailsArray, "otherPropertyDetailsArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"otherApply"} onClick={this.applyOtherSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.otherPropertyDetailsText}</Typography>
                            <img src={editFileIcon} data-test-id={"otherEdit"} onClick={() => this.handleEditClick("otherDetails")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.otherPropertyDetails.map(other =>
                            <Box className="flexDiv" key={other.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{other.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderLocalRecomadedCard = () => {
        return (
            this.state.editInstruction.localRecomadations ?
                <>
                    {this.state.localRecommandedArray.map((local, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{local.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {local.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            value={item.description}
                                            data-test-id={"localChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleLocalRecommandedInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"localAddNew"}
                                        onClick={() => this.addNewSection(this.state.localRecommandedArray, "localRecommandedArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"localApply"} onClick={this.applyLocalRecomandedSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.subInstructionText.localRecommandedText}</Typography>
                            <img src={editFileIcon} data-test-id={"localEdit"} onClick={() => this.handleEditClick("localRecomadations")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.localRecommandedDetails.map(localVal =>
                            <Box className="flexDiv" key={localVal.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{localVal.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderCarCheckInCheckOutCard = () => {
        return (
            this.state.editCarKeys.checkInDateAndTime ?
                <>


                    {this.state.carCheckInArray.map((carCheckInAttr, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{carCheckInAttr.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {carCheckInAttr.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            data-test-id={"carCheckInChange"}
                                            value={item.description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCarCheckInInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyCarCheckIn"} onClick={this.applyCarCheckInSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.carSubInstructionText.checkInDateTimeText}</Typography>
                            <img src={editFileIcon} data-test-id={"editCarCheckIn"} onClick={() => this.handleCarEdit("checkInDateAndTime")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.carCheckInDetails.map(CheckInItem =>
                            <Box className="flexDiv" key={CheckInItem.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{CheckInItem.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderCarHostGuidelinesCard = () => {
        return (
            this.state.editCarKeys.hostGuidelines ?
                <>
                    {this.state.carHostGuidelineArray.map((hostGuide, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{hostGuide.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {hostGuide.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            disabled={this.handleViewOnly(index)}
                                            readOnly={this.handleViewOnly(index)}
                                            value={item.description}
                                            data-test-id={"hostGuideChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCarHostGuidelineInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"addNewHostGuide"}
                                        onClick={() => this.addNewSection(this.state.carHostGuidelineArray, "carHostGuidelineArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyHostGuide"} onClick={this.applyCarHostGuidelinesSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.carSubInstructionText.hostGuidelinesText}</Typography>
                            <img src={editFileIcon} data-test-id={"editHostGuide"} onClick={() => this.handleCarEdit("hostGuidelines")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.carHostGuidelinesDetails.map(hostGuideAttr =>
                            <Box className="flexDiv" key={hostGuideAttr.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{hostGuideAttr.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderCarPickupCard = () => {
        return (
            this.state.editCarKeys.pickupInstruction ?
                <>
                    {this.state.carPickUpArray.map((pickUpItem, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{pickUpItem.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {pickUpItem.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            value={item.description}
                                            data-test-id={"pickUpChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCarPickUpInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"addNewPickUp"}
                                        onClick={() => this.addNewSection(this.state.carPickUpArray, "carPickUpArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyCarPickUp"} onClick={this.applyCarPickUpSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.carSubInstructionText.pickUpText}</Typography>
                            <img src={editFileIcon} data-test-id={"editPickUp"} onClick={() => this.handleCarEdit("pickupInstruction")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.carPickUpDetails.map(pickUpAttr =>
                            <Box className="flexDiv" key={pickUpAttr.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{pickUpAttr.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderCarReturnCard = () => {
        return (
            this.state.editCarKeys.returnInstruction ?
                <>
                    {this.state.carReturnArray.map((returnItem, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{returnItem.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {returnItem.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            value={item.description}
                                            data-test-id={"returnChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCarReturnInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"addNewreturn"}
                                        onClick={() => this.addNewSection(this.state.carReturnArray, "carReturnArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyCarReturn"} onClick={this.applyCarReturnSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.carSubInstructionText.returnText}</Typography>
                            <img src={editFileIcon} data-test-id={"editReturn"} onClick={() => this.handleCarEdit("returnInstruction")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.carReturnDetails.map(returnAttr =>
                            <Box className="flexDiv" key={returnAttr.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{returnAttr.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderCarInfoCard = () => {
        return (
            this.state.editCarKeys.carInstruction ?
                <>
                    {this.state.carInfoArray.map((infoItem, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{infoItem.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {infoItem.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            value={item.description}
                                            data-test-id={"infoChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCarInfoInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"addNewInfo"}
                                        onClick={() => this.addNewSection(this.state.carInfoArray, "carInfoArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyCarInfo"} onClick={this.applyCarInfoSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.carSubInstructionText.carInstructionText}</Typography>
                            <img src={editFileIcon} data-test-id={"editInfo"} onClick={() => this.handleCarEdit("carInstruction")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.carInfoDetails.map(infoAttr =>
                            <Box className="flexDiv" key={infoAttr.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{infoAttr.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

    renderCarSpecificationCard = () => {
        return (
            this.state.editCarKeys.carSpecification ?
                <>
                    {this.state.carSpecificationArray.map((specifyItem, index) =>
                        <Box className="mainInstructionBox" key={`sub-${index}`}>
                            <Box className="grayInstructionBox">
                                <Box className="headerInstructionBox">
                                    <Typography className="subPolicyText">{specifyItem.name}</Typography>
                                </Box>
                            </Box>
                            <Box className="whiteInstructionBox">
                                {specifyItem.sections_attributes.map((item, index) =>
                                    <Box className="inputBox" key={`attributes-${item}`}>
                                        <Typography className="titleText">{item.title}</Typography>
                                        <OutlinedInput
                                            className="textField"
                                            fullWidth
                                            value={item.description}
                                            data-test-id={"specificationChange"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCarSpecificatinInputChange(event, index)}
                                        />
                                    </Box>
                                )}

                                <Box className="sectionBox">
                                    <Button
                                        className="addBtn"
                                        fullWidth
                                        data-test-id={"addNewSpecification"}
                                        onClick={() => this.addNewSection(this.state.carSpecificationArray, "carSpecificationArray")}
                                        endIcon={<AddCircleOutlineIcon />}
                                    >
                                        Add New Section
                                    </Button>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box></Box>
                                    <Button className="applyBtn" data-test-id={"applyCarSpecification"} onClick={this.applyCarSpecificationSubInstructions}>APPLY</Button>
                                </Box>


                            </Box>

                        </Box>
                    )}
                </>
                :
                <Box className="mainInstructionBox">
                    <Box className="grayInstructionBox">
                        <Box className="headerInstructionBox">
                            <Typography className="subPolicyText">{this.carSubInstructionText.carSpecificationText}</Typography>
                            <img src={editFileIcon} data-test-id={"editSpecification"} onClick={() => this.handleCarEdit("carSpecification")} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box className="whiteInstructionBox">
                        {this.state.carSpecificationDetails.map(specificationAttr =>
                            <Box className="flexDiv" key={specificationAttr.instructionID}>
                                <CheckCircleIcon className="listIcon" />
                                <Typography className="titleText">{specificationAttr.cardTitle}</Typography>
                            </Box>
                        )}


                    </Box>

                </Box>
        )
    }

  render() {
    // Customizable Area Start
    
    return (
      <div>

        <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />

            {this.state.loader ? <Loader loading={true} />
                :
                <GuestInstructionWrapper>

                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <Button
                                onClick={this.goBack}
                                className="previousBtn"
                                data-test-id="backPage"
                                startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                                Back
                            </Button>
                            <Typography className="headerInstructionText">Guest Instructions</Typography>
                            <Typography className="welcomeText">Welcome, please fill in the input fields to allow your
                                clients have a seamless experience.
                            </Typography>
                            {this.handleTypeCheck(this.state.catalougeType) ?
                                <div>
                                    <Box style={{ marginTop: "25px" }}>
                                        <Typography className="policyNameText">{this.carMainInstructionText.carPoliciyText}</Typography>
                                        {this.renderCarCheckInCheckOutCard()}
                                        {this.renderCarHostGuidelinesCard()}
                                    </Box>

                                    <Box style={{ marginTop: "25px" }}>
                                        <Typography className="policyNameText">{this.carMainInstructionText.carPickUpReturnText}</Typography>
                                        {this.renderCarPickupCard()}
                                        {this.renderCarReturnCard()}
                                    </Box>

                                    <Box style={{ marginTop: "25px" }}>
                                        <Typography className="policyNameText">{this.carMainInstructionText.carInfoDetailsText}</Typography>
                                        {this.renderCarInfoCard()}
                                        {this.renderCarSpecificationCard()}
                                    </Box>

                                </div>
                                :
                                <div>
                                    <Box style={{ marginTop: "25px" }}>
                                        <Typography className="policyNameText">{this.mainInstructionText.housePoliciyText}</Typography>
                                        {this.renderCheckInCheckOutCard()}
                                        {this.renderHouseRulesCard()}
                                    </Box>

                                    <Box style={{ marginTop: "25px" }}>
                                        <Typography className="policyNameText">{this.mainInstructionText.checkInInstructionText}</Typography>
                                        {this.renderLocationDetailsCard()}
                                        {this.renderAccessMethodCard()}
                                        {this.renderParkingInfoCard()}
                                        {this.renderWifiInfoCard()}
                                        {this.renderOtherPropertyCard()}
                                        {this.renderLocalRecomadedCard()}
                                    </Box>
                                </div>
                            }

                            
                        </Grid>
                        <Grid item md={4}>
                           <PricingCard />
                        </Grid>
                    </Grid>

                </GuestInstructionWrapper>
            }

            
        <Footer />
         
      </div>
    );
    // Customizable Area End
  }
}

export default GuestInstruction;

const GuestInstructionWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .headerInstructionText" : {
        fontSize: '30px',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        color: '#101828',
        margin: "20px 0 5px 0"
    },
    "& .previousBtn": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#667085',
        textTransform: "none",
        background: "white",
    },
    "& .welcomeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#7D8086',
        maxWidth: "340px"
    },
    "& .policyNameText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '24px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .subPolicyText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .mainInstructionBox" : {
        border: "1px solid #E7E7E7",
        borderRadius: "12px",
        margin: "15px 0"
      },
      "& .grayInstructionBox" : {
        background : "#F4F7FC",
        borderBottom: "1px solid #E7E7E7",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
      },
      "& .headerInstructionBox" : {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
      }, 
      "& .whiteInstructionBox" : {
        background :"white",
        padding: "15px",
        borderRadius: "12px",  
      },
      "& .titleText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#23395D',
      },
      "& .textField" : {
        height: 45,
        padding: "10px 16px",
        fontWeight: 500,
        color: "#94A3B8",
        fontSize: "14px",
        fontFamily: 'Plus Jakarta Sans',
        borderRadius: "10px",
        marginBottom: "10px"
      },
      "& .textField .MuiOutlinedInput-input" : {
        padding : 0,
      },
      "& .applyBtn" : {
        height: 45,
        width: 160,
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Plus Jakarta Sans',
        textTransform: "none",
        fontWeight: 600,
        background: "#3173E1",
        borderRadius: "12px",
      },
      "& .flexDiv" : {
        display: "flex",
        gap : "10px",
        margin: "10px 0"
      },
      "& .listIcon" : {
        color: "#3173E1", 
        height: 20, 
        width: 20,
      },
      "& .addBtn" : {
        background: "#F0F5FF",
        color: '#3173E1',
        fontSize: '14px',
        fontFamily: 'Plus Jakarta Sans',
        textTransform: "none",
        fontWeight: 600,
        margin: "10px 0"
      }
})

// Customizable Area End