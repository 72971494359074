// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Typography,
    Grid,
    OutlinedInput,
    Tab,
    Tabs,
    Select,
    MenuItem,
    Divider,
    Radio
} from "@material-ui/core"

import AppHeader from "../../notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Chart } from "react-google-charts";

import { searchIcon } from "../../../blocks/reservations/src/assets";
import { downIcon, filterButton } from "./assets";


import HostSettingsController, {
    Props,
  } from "./HostSettingsController";

class HostSettings extends HostSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderPayoutModule = () => {
    return (
        <Box>
            {this.renderStateUpdateBackBtn()}
            
            {this.renderTitlleBox("Payout Schedule")}

            {this.state.loader ?
                <Loader loading />
                :
                <Grid container spacing={3}>
                    <Grid item md={8}>
                        {this.payoutSelection.map(item =>
                            <Box className={`${this.handlePayouSelectionCondition(item.payOutMode) ? "selectedPayoutBox" : "nonSelectedPayOutBox"} payOutBox`} key={item.id}>
                                <Box>
                                    <Typography className="payoutTitle">{item.payOutTitle}</Typography>
                                    <Typography className="payoutSubTitle">{item.payOutSubTitle}</Typography>
                                </Box>
                                <Box>
                                    <Radio
                                        style={{ color: this.handlePayouSelectionCondition(item.payOutMode) ? "#3273E1" : "#B0B0B0" }}
                                        checked={this.handlePayouSelectionCondition(item.payOutMode)}
                                        value={item.payOutMode}
                                        data-test-id="payoutSelection"
                                        onChange={this.changePayoutMode}
                                    />
                                </Box>
                            </Box>
                        )}

                        <Typography className="headerText">Transactions for 2023</Typography>
                        <Typography className="subHeaderText">January 2023</Typography>

                        <Box className="historyBox">
                            <Box style={{ margin: "15px 0" }}>
                                <Box style={{ margin: "15px 0" }}>
                                    <Box className="flexBox" >
                                        <Box style={{ display: "flex", gap: 8 }}>
                                            <img src={downIcon} />
                                            <Box>
                                                <Typography className="amountText">{"Automatic Payout"}</Typography>
                                                <Typography className="subAmountText">{"Jan 14 2023"}</Typography>
                                            </Box>

                                        </Box>
                                        <Typography style={{ color: "#3173E1" }} className="priceAmtText">{"$00.00"}</Typography>
                                    </Box>
                                </Box>
                            </Box>


                        </Box>
                        <br />

                        <Box className="historyBox">
                            <Typography className="historyText" style={{ marginBottom: "15px" }}>Payouts</Typography>
                            <Divider style={{ margin: "10px 0" }} />
                            {this.state.payOutData.map(itemVal =>
                                <Box key={itemVal.id} style={{ margin: "15px 0" }}>
                                    <Typography className="historyText">{itemVal.payOutMonth}</Typography>
                                    {itemVal.transactions.map((paymentVal: any, index: number) =>
                                        <Box style={{ margin: "15px 0" }} key={paymentVal.id}>
                                            <Box className="flexBox" >
                                                <Box style={{ display: "flex", gap: 8 }}>
                                                    <img src={paymentVal.payOutIcon} />
                                                    <Box>
                                                        <Typography className="amountText">{paymentVal.payOutText}</Typography>
                                                        <Typography className="subAmountText">{paymentVal.payOutDate}</Typography>
                                                    </Box>

                                                </Box>
                                                <Typography style={{ color: "#01764D" }} className="priceAmtText">${paymentVal.payOutPrice}</Typography>
                                            </Box>
                                            {itemVal.transactions.length - 1 !== index && <Divider style={{ margin: "14px 0" }} />}
                                        </Box>
                                    )}
                                </Box>
                            )}


                        </Box>

                    </Grid>
                </Grid>
            }

            
        </Box>
    )
  }

  renderTransactionHistoryModule = () => {
    return (
        <Box>

            {this.renderStateUpdateBackBtn()}

            {this.renderTitlleBox("Transaction History")}

            <Grid container spacing={3}>
                <Grid item md={8}>
                    <Box className="historyBox">
                        <Box className="flexBox" style={{ marginBottom: "15px"}}>
                            <Typography className="historyText">Transaction History</Typography>
                            <img src={filterButton} />
                        </Box>
                        <Divider style={{ margin : "10px 0"}} />
                        {this.transactionHistoryData.map(item => 
                            <Box key={item.id} style={{ margin : "15px 0"}}>
                                <Typography className="historyText">{item.monthTittle}</Typography>
                                {item.data.map(value =>
                                  <Box style={{ margin : "15px 0"}} key={value.id}>
                                    <Box className="flexBox" >
                                        <Box style={{ display: "flex", gap: 8 }}>
                                            <img src={value.icon} />
                                            <Box>
                                                <Typography className="amountText">{value.title}</Typography>
                                                <Typography className="subAmountText">{value.date}</Typography>
                                            </Box>

                                        </Box>
                                        <Typography className="priceAmtText">{value.price}</Typography>
                                    </Box>
                                    <Divider style={{ margin : "14px 0"}} />
                                  </Box>
                                )}
                            </Box>
                        )}
                        
                        
                    </Box>

                </Grid>
            </Grid>
        </Box>
    )
  }

  renderTaxInfoModule = () => {
    return (
        <Box>
           {this.renderStateUpdateBackBtn()}

            <Grid container spacing={3}>
                <Grid item md={8}>
                    <Box>
                        {this.renderTitlleBox("Tax Information")}

                        <Box className="earningBox" style={{ marginBottom: "20px"}}>
                            <Box className="flexBox graphHeaderBox">
                                <Box>
                                    <Typography className="grossText">Gross Earnings</Typography>
                                    <Typography className="grossAmount">$25,500.06</Typography>
                                </Box>
                                <Box style={{ marginRight : 20}}>
                                    <Select
                                      disableUnderline
                                      variant="outlined"
                                      defaultValue={new Date().getFullYear()}
                                      style={{
                                        height: 40,
                                        padding: 0
                                      }}
                                    >
                                        {this.lastFiveYearsArray().map(option =>
                                          <MenuItem value={option}>{option}</MenuItem>    
                                        )}
                                    </Select>
                                </Box>
                            </Box>

                            <Box className="chartBox">
                                <Chart
                                    chartType="Bar"
                                    width="100%"
                                    height="300px"
                                    data={this.state.graphData}
                                />
                            </Box>
                        </Box>

                        <Box className="earningBox">
                            <Box className="graphHeaderBox">
                                <Typography className="grossText">Roam Fees</Typography>
                                <Typography className="grossAmount">$500.06</Typography>
                            </Box>
                            <Box className="chartBox">
                                <Typography className="feesText">
                                    Represents the amount Roam received from your trip reservations, based on the percentage rate of your chosen protection plan for each trip. Please note payments deposited into your bank account have Roam fees already deducted.
                                </Typography>
                                <Box className="feesSubTextBox">
                                    <Typography className="feesText" style={{ maxWidth: 680}}>
                                         Roam fees may be considered an operating expense or deduction when you report gross earnings as income on
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

            </Grid>


          
        </Box>
    )
  }

  renderClaimsModule = () => {
    return (
        <Box>

            {this.renderStateUpdateBackBtn()}

            {this.renderTitlleBox("Claims")}

            <OutlinedInput
                placeholder="Search here"
                className="searchField"
                startAdornment={<img src={searchIcon} />}
                data-test-id="searchfield"
                onChange={this.handleSearch}
            />

            <Box className="flexBox" style={{ margin: "25px 0"}}>

                <Box className="tabWrapper">
                    <Tabs
                        className="tabBox"
                        value={this.state.claimTab}
                        data-test-id="tabs"
                        onChange={this.handleTabChanges}
                    >
                        <Tab label="All Status" className="tabText"></Tab>
                        <Tab label="Pending" className="tabText"></Tab>
                        <Tab label="Under Investigation" className="tabText"></Tab>
                    </Tabs>
                </Box>
            </Box>

            <Box>

                {this.state.claimTab === 0 && this.handleFilterAccordingTab("all")}
                {this.state.claimTab === 1 && this.handleFilterAccordingTab("pending")}
                {this.state.claimTab === 2 && this.handleFilterAccordingTab("under_investigation")}
                {this.state.claimData.length === 0 && <Box className="errortext">No Claims Available</Box>}

            </Box>
            
        </Box>
    )
  }

  handleFilterAccordingTab = (status : string) => {
     return  status === "all" ?
          <Grid container spacing={3}>
              {this.state.claimData.map((item => this.claimItem(item)))}
          </Grid>
          :
          <Grid container spacing={3}>
              {this.state.claimData.filter(item => item.claimStatus === status).map((item => this.claimItem(item)))}
          </Grid>
  }

    claimItem = (item: any) => {
        return (
            <Grid item md={4} key={item.id}>
                <Box className="claimsCardBox">
                    <Box className="flexBoxClaimCard">
                        <Box>
                            <Typography className="claimText">{item.claimTitle}</Typography>
                            <Typography className="claimSubText">{item.claimSubTitle}</Typography>
                            {this.handleClaimStatusChip(item.claimStatus)}
                        </Box>
                        <Typography className="claimDateText">{item.claimDate}</Typography>
                    </Box>

                </Box>
            </Grid>
        )
    }

  handleClaimStatusChip = (status : string) => {
    switch(status) {
        case "pending" :
            return <Box className="pendingStatus">Pending</Box>
        case "under_investigation": 
            return <Box className="investigationStatus">Under Investigation</Box>
        case "resolved" :
            return <Box className="resolvedStatus">Resolved</Box>

    }
  }

  renderSettingModule = () => {
    return (
        <Box>
            {this.renderNormalBackBtn()}

            <Grid container spacing={3}>
                <Grid item md={8}>
                    <Box>

                        {this.renderTitlleBox("Host Settings")}

                        <Box className="accInfoBox">
                            <Box className="flexBox grayBox">
                                <Typography className="oweText">We Owe You</Typography>
                                <Box>
                                    <Typography className="priceText">$0</Typography>
                                    <Typography className="smallText">Scheduled: 07/03/2023</Typography>
                                </Box>
                            </Box>

                            <Box className="flexBox" style={{ padding: 15}}>
                                <Typography className="accText">Direct Deposit Account ******8000</Typography>
                                <Typography className="editText">Edit Direct Deposit</Typography>
                            </Box>
                        </Box>

                        {this.settingModules.map(item => {
                            return (
                                <Box className="outerBox" data-test-id="selectedModule" onClick={() => this.changeSettingsModule(item.moduleName)} key={item.id}>
                                    <Box className="innerBox">
                                        <Box className="innerFlexBox">
                                            <img src={item.icon} />
                                            <Typography className="subText">{item.title}</Typography>
                                        </Box>
                                        <ArrowForwardIosIcon style={{ height: 24, color: "#23395D" }} fontSize="small" />
                                    </Box>

                                </Box>
                            )
                        })}


                    </Box>
                </Grid>

            </Grid>

        </Box>
    )
  }

    renderNormalBackBtn = () => {
        return (
            <Button
                onClick={this.navigateToBack}
                className="backButton"
                data-test-id="backBtn"
                startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                Back
            </Button>
        )
    }

    renderStateUpdateBackBtn = () => {
        return (
            <Button
                onClick={this.backToSettingsModule}
                data-test-id="backToSettingsPage"
                className="backButton"
                startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                Back
            </Button>
        )
    }

    renderTitlleBox = (titleValue : string) => {
        return <Typography className="titleText">{titleValue}</Typography>
    }

  renderModule = () => {
    switch(this.state.moduleName) {
        
        case "settingsModule":
            return this.renderSettingModule();
        case "payoutModule":
            return this.renderPayoutModule();
        case "transactionHistoryModule":
            return this.renderTransactionHistoryModule();
        case "taxInfoModule":
            return this.renderTaxInfoModule();
        case "claimsModule":
            return this.renderClaimsModule();
    }
  }

  render() {
    // Customizable Area Start
    
    return (
      <div>

        <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />
        <SettingsWrapper>
                {this.renderModule()}
        </SettingsWrapper>

        <Footer />
         
      </div>
    );
    // Customizable Area End
  }
}

export default HostSettings;

const SettingsWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .backButton": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        textTransform: "none",
        background: "white",
        color: '#667085',
    },
    "& .titleText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '30px',
        color: '#23395D',
        margin: "30px 0"
    },
    "& .outerBox" : {
        margin: "15px 0",
        padding: "20px 25px",
        border: "1px solid #DADBDE",
        borderRadius: "12px",
        cursor: "pointer"
    },
    "& .innerBox": {
        display : "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .innerFlexBox" : {
        display: "flex",
        gap: "10px"
    },
    "& .subText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '16px',
        color: '#23395D',
    },
    "& .flexBox": {
        display : "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .oweText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '16px',
        color: '#23395D',
    },
    "& .priceText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '24px',
        textAlign: "center",
        color: '#3173E1',
    },
    "& .smallText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '10px',
        color: '#B0B0B0',
    },
    "& .accText" : {
        width: "140px",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#596C8A',
    },
    "& .editText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '12px',
        color: '#3173E1',
        textAlign: "center",
    },
    "& .grayBox" : {
        padding: '15px',
        background: 'rgb(244, 247, 252)',
        borderTopColor: '#E7E7E7',
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px'
    },
    "& .accInfoBox" : {
        borderRadius: "12px",
        border: "1px solid #E7E7E7"
    },
    "& .searchField" : {
        padding: "10px 16px",
        height: 40,
        width: "90%",
        borderRadius: "12px",
        fontSize: "14px",
        fontFamily: 'Plus Jakarta Sans',
        color: "#475467",
        fontWeight: 600
      },
      "& .searchField .MuiOutlinedInput-input" : {
        padding : 10,
      },
      "& .tabBox .Mui-selected": {
        color: "#3173E1",
        borderBottom: "none"
    },
    "& .tabBox .MuiTabs-indicator": {
        backgroundColor: "#3173E1",
    },
    "& .tabText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#475467",
        borderBottom: "1px solid #E2E8F0"
    },
    "& .flexBoxClaimCard" : {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .claimsCardBox" : {
        border: "1px solid #E7E7E7",
        borderRadius: "12px",
        padding: "35px 20px",
        minHeight: "110px",
        boxShadow: "0px 4px 5px 0px #0000000D"
    },
    "& .claimText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '14px',
        color: '#23395D',
    },
    "& .claimSubText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#4C6081',
        maxWidth: "250px"
    },
    "& .claimDateText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '12px',
        color: '#B0B0B0',
        textAlign: "center",
    },
    "& .pendingStatus":{
        width: 75,
        borderRadius: 100,
        background:"#FFDCDC",
        border : "1px solid #E45A5A",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#E45A5A',
        textAlign: "center",
        margin : "10px 0",
        padding: "8px"
    },
    "& .investigationStatus":{
        width: 120,
        borderRadius: 100,
        background:"#FEF5E6",
        border : "1px solid #F49600",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#F49600',
        textAlign: "center",
        margin : "10px 0",
        padding: "8px"
    },
    "& .resolvedStatus":{
        width: 75,
        borderRadius: 100,
        background:"#DCFBE5",
        border : "1px solid #14C345",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#14C345',
        textAlign: "center",
        margin : "10px 0",
        padding: "8px"
    },
    "& .earningBox": {
        borderRadius: "12px",
        border: "1px solid #E7E7E7",
        boxShadow: "0px 4px 5px 0px #0000000D"
    },
    "& .graphHeaderBox" :{
        padding: '16px',
        background: '#F4F7FC',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
    },
    "& .chartBox": {
        padding: '16px',
        background: 'white',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
    },
    "& .grossText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '14px',
        color: '#23395D',     
    },
    "& .grossAmount": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '30px',
        color: '#23395D',     
    },
    "& .feesText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        color: '#4C6081', 
    },
    "& .feesSubTextBox" : {
        padding: "14px 17px",
        margin: "10px 0",
        background: '#F4F7FC',
        borderRadius: 6
    },
    "& .errortext" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: "#23395D",
        textAlign: "center",
        margin : "15px 0px"
    }, 
    "& .historyBox" : {
        padding: "15px",
        borderRadius: "12px",
        border: "1px solid #E7E7E7",
        boxShadow: "0px 4px 5px 0px #0000000D",
    }, 
    "& .historyText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: "#23395D",
    },
    "& .amountText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '16px',
        color: "#23395D",
    },
    "& .subAmountText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 600,
        color: "#4C6081",
    },
    "& .priceAmtText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '20px',
        color: "#23395D",
    },
    "& .payOutBox" : {
        display : "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 25px",
        borderRadius: "12px",
        boxShadow: "0px 4px 5px 0px #0000000D",
        marginBottom: "15px"
    },
    "& .selectedPayoutBox" : {
        border: "1px solid #3273E1",
        background: "#F1F6FF"
    },
    "&  .nonSelectedPayOutBox" : {
        border: "1px solid #DDE0E6",
        background: "white"
    },
    "& .payoutTitle" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '14px',
        color: "#23395D",
    },
    "& .payoutSubTitle" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: "#94A3B8",
    },
    "& .headerText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 700,
        fontSize: '16px',
        color: "#23395D",
        marginBottom: "15px"
    },
    "& .subHeaderText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '16px',
        color: "#23395D",
        marginBottom: "10px"
    }
})

// Customizable Area End