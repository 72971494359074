// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import moment from "moment"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface RenterProfile {
  renterId : string
  renterName: string
  renterTrips: string
  renterJoined : string
  renterProfile: string
}

interface S {
  // Customizable Area Start  
  getReservationInfo : any,
  tabValue : number,
  loading : boolean,
  checkOutModal: boolean,
  receiverId : string
  renterInfo: RenterProfile,
  unreadMsgCount : number,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReservationDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   getReservationInfoApiCallId: string = ""
   getRenterProfileApiCallId: string = ""
   getChatCountApiCallId: string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
       getReservationInfo : {},
       tabValue: 0,
       loading : true,
       checkOutModal: false,
       receiverId: "",
      renterInfo: {
        renterId: "",
        renterName: "",
        renterTrips: "",
        renterJoined: "",
        renterProfile: "",
      },
      unreadMsgCount : 0,

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getReservationInfoApiCallId: 
            return this.handleResponseForGetBookedSlotApi(responseJson)
        case this.getRenterProfileApiCallId:
            return this.handleResponseForRenterProfileApi(responseJson)
        case this.getChatCountApiCallId:
            return this.handleResponseForGetChatCountApi(responseJson)
        default:
            break;
      }

      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const actualId =  this.props.navigation.getParam("actualId")
    this.getBookedSlotDetails(actualId)
      
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.receiverId !== this.state.receiverId) {
        this.getChatCount()
    }
  }

  handleResponseForGetBookedSlotApi = (responseJson: any) => {
    if(responseJson && responseJson.catalogue && responseJson.catalogue.data) {
      const hostId = responseJson.catalogue.data.attributes.hosted_by.data.id
      const renterId = responseJson.catalogue.data.attributes.renter_id
      const reservationStartDate = moment(responseJson.catalogue.data.attributes.start_time).format('MMM Do')
      const reservationEndDate = moment(responseJson.catalogue.data.attributes.end_time).format('MMM Do')
      localStorage.setItem("reservationStartDate", reservationStartDate)
      localStorage.setItem("reservationEndDate", reservationEndDate)
       this.setState({ getReservationInfo : responseJson.catalogue.data,receiverId: hostId}, () => this.getRenterInfo(renterId))
    } else {
      this.setState({ getReservationInfo : {}, loading: false})
    }
  }

  handleResponseForGetChatCountApi = (responseJson: any) => {
    if(responseJson && responseJson.unread_messages_count) {
      const unreadCount = truthyValue(responseJson.unread_messages_count);
      this.setState({ unreadMsgCount : unreadCount})
    } else {
      this.setState({ unreadMsgCount : 0})
    }
  }

  handleResponseForRenterProfileApi = (responseJson: any) => {
    if (responseJson && responseJson.account && responseJson.account.data) {
      const responseObj = responseJson.account.data.attributes
      const renterInfo = {
        renterId: truthyValue(responseJson.account.data.id),
        renterName: truthyValue(responseObj.full_name),
        renterTrips: truthyValue(responseJson.render_trip_completed),
        renterJoined: truthyValue(responseObj.joined),
        renterProfile: truthyValue(responseObj.profile_image_url.url),
      }
  
      this.setState({ renterInfo: renterInfo, loading : false })
    } else {
      this.setState({ loading: false })
    }
  }

  getChatCount = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getChatCountApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiTypeGet,
      endPoint: `${configJSON.getChatCountApiEndpoint}/${this.state.receiverId}`,
    })
  }

  getBookedSlotDetails = (bookedSlotId : number) => {
    const header = {
        "Content-Type": configJSON.contentType,
        "token": localStorage.getItem("userToken")
      };
  
      this.getReservationInfoApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiTypeGet,
        endPoint: `${configJSON.getReservationDetailApiEndPoint}/${bookedSlotId}`
      })
  }

  getRenterInfo = (renterId : string) => {
    const header = {
        "Content-Type": configJSON.contentType,
        "token": localStorage.getItem("userToken")
      };
  
      this.getRenterProfileApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getRenterProfileApiEndPoint}?id=${renterId}`,
        method: configJSON.apiTypeGet,
      })
  }

  handleTabChanges = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabValue: value})
  }

  handleImageUrl = (item : any) => {
    // imageUrl
    let imgUrl : string = ""
    if(item.attributes && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
      imgUrl = item.attributes.images[0].url
    }

    return imgUrl 
  }

  closeCheckOutModal = () => {
     this.setState({ checkOutModal : false})
  }

  openCheckOutModal = () => {
    this.setState({ checkOutModal : true})
 }
  
  navigateToCheckout = () => {
    const actualId =  this.props.navigation.getParam("actualId")
    this.props.navigation.navigate("ReservationCheckOut", { reservationId : actualId})
  }

  navigateToReservationPage = () => {
    this.props.navigation.navigate("Reservation")
  }

  handleStartTimeHours = (startTime : string) => {        
        const currentTime = moment();
        
        const startMoment = moment(startTime);
        
        const difference = startMoment.diff(currentTime, 'hours');

        const days = Math.floor(difference / 24);

        if(difference < 48) {
          return  `${difference} Hours.`
        } else {
          return `${days} Days`
        }

        

  }

  setCountFn = (count : number) => {
    this.setState({ unreadMsgCount : count })
  }

  // Customizable Area End
}

// Customizable Area End