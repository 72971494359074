// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue, convertUrlsToFiles } from '../../../components/src/asset'
import { instructionIcon, locationIcon, protectionIcon } from "./assets";
import { toast } from  "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reviews {
    count : number,
    rating : number
}

interface InfoBox {
    catalougeName: string,
    catalougeReviews: Reviews,
    catalougeImg : string
}

interface NavigationObject {
  navigateTo : string,
  subId : string
}

interface S {
  // Customizable Area Start  
  loader : boolean,
  catalougeId: string,
  catalougeType: string,
  catalougeInfo : InfoBox,
  catalougePhotos: File[],
  uploadedCatalougePhotos: string[],
  catalougeDescription : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ListingSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  imgRef: React.RefObject<HTMLInputElement>
  getCatalougeDetailsApiCallId: string = ""
  updateCatalougeDetailsApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loader: true,
        catalougeId: "",
        catalougeType: "",
        catalougeInfo: this.infoBox,
        catalougePhotos: [],
        uploadedCatalougePhotos: [],
        catalougeDescription: ""
    };
    this.imgRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getCatalougeDetailsApiCallId :
             return this.handleGetCatalougeInfoResponse(responseJson)
        case this.updateCatalougeDetailsApiCallId : 
             return this.handleUpdateCatalougeInfoResponse(responseJson)
        default :
              break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const catalougeId = this.props.navigation.getParam("catalogueId")
    this.getCatalougeInfo(catalougeId) 
  }

  infoBox : InfoBox = {
    catalougeName : "",
    catalougeImg: "",
    catalougeReviews : {
        count: 0,
        rating: 0
    }
  }

  settingArray = [
    {
        id : 1,
        title : "Guest Instructions",
        icon : instructionIcon,
        navigation : {
          navigateTo : "GuestInstructions",
          subId :this.props.navigation.getParam("catalogueId"),
        }
        
    },
    {
        id : 2,
        title : "Location & Distance Inc.",
        icon : locationIcon,
        navigation : {
          navigateTo : "LocationDistances",
          subId : this.props.navigation.getParam("catalogueId"),
        }
    },
    {
        id : 3,
        title : "Extras & Addons",
        icon : protectionIcon,
        navigation : {
          navigateTo : "ExtraAddOn",
          subId : this.props.navigation.getParam("catalogueId"),
        }
    },
    {
        id : 4,
        title : "Trip Preferences",
        icon : instructionIcon,
        navigation : {
          navigateTo : "TripPreference",
          subId : this.props.navigation.getParam("catalogueId"),
        }
    },
    {
        id : 5,
        title : "Trip History",
        icon : protectionIcon,
        navigation: {
          navigateTo: "TripHistory",
          subId: this.props.navigation.getParam("catalogueId"),
        }
        
    },
    {
        id : 6,
        title : "Protection Plan",
        icon : protectionIcon,
        navigation : {
          navigateTo : "ProtectionPlans",
          subId : "",
        }
    }
  ]

  handleGetCatalougeInfoResponse = async (responseJson : any) => {
    if(responseJson && responseJson.catalogue && responseJson.catalogue.data) {
        const responseObject = responseJson.catalogue.data.attributes
        const description = this.handleDescriptionText(responseObject.features, this.props.navigation.getParam("catalogueType"))
        const catalougeId = this.props.navigation.getParam("catalogueId")
        const catalougeType = this.props.navigation.getParam("catalogueType")
        const headerBox = {
            catalougeName: truthyValue(responseObject.name),
            catalougeImg: this.handleImageUrl(responseObject.images),
            catalougeReviews: {
                count: 0,
                rating: 0
            }
        }

        const imageUrls = responseObject.images.map((image: any) => image.url);

        const imgArr = await convertUrlsToFiles(responseObject.images).then((files) => {
          return files
      });
    
        this.setState({ 
          loader : false, 
          catalougeId : catalougeId, 
          catalougeType :catalougeType, 
          catalougeInfo: headerBox, 
          catalougePhotos : imgArr , 
          uploadedCatalougePhotos: imageUrls,
          catalougeDescription: description
        })


    } else {
        this.setState({ loader : false})
    }
  }
 
  handleUpdateCatalougeInfoResponse = (responseJson : any) => {
    if(responseJson && responseJson.message) {
        toast.success("Catalogue has been updated successfully")
        this.getCatalougeInfo(this.state.catalougeId)
    } else {
        this.setState({ loader : false})
        toast.error("Something went wrong")
    }
  }


  getCatalougeInfo = (catalougeId : string) => {
    const headerWeb = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getCatalougeDetailsApiCallId = apiCalling({
        header: JSON.stringify(headerWeb),
        endPoint: `${configJSON.getCatalougeInfoApiEndpoint}/${catalougeId}`,
        method: configJSON.apiMethodTypeGet
      })
  }

  updateCatalougeInfo = () => {

    if(this.state.catalougePhotos.length === 0) {
      toast.error("Please add atleast 1 image to continue")
      return false
    }


    this.setState({ loader : true})

    const header = {
        "token": localStorage.getItem("userToken")
      };

      const formData = new FormData();

      this.state.catalougePhotos.forEach(value => {
        formData.append("catalogue[images][]", value);
      });
      
      this.updateCatalougeDetailsApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.updateCatalougeInfoApiEndpoint}/${this.state.catalougeId}`,
        method: configJSON.apiMethodTypePut,
        body: formData
      })
  }

  navigateToPage = (navigationObj : NavigationObject) => {
     
    const navUrl = navigationObj.navigateTo;
    const catalogeId = navigationObj.subId
     
        if (navUrl !== "" && catalogeId === "") {
          this.props.navigation.navigate(navUrl)
          return false
        } else if (navUrl !== "" && catalogeId !== "") {
          this.props.navigation.navigate(navUrl, { "subId" : catalogeId})
          return false
        } 
    };

  handleImageUrl = (images : any) => {

    // imageUrl

    let imgUrl : string = ""
    if(images && images.length > 0 && images[0].url ) {
      imgUrl = images[0].url
    }

    return imgUrl
  } 

  changeImages = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        catalougePhotos: [...prevState.catalougePhotos, ...newFiles],
        uploadedCatalougePhotos: [...prevState.uploadedCatalougePhotos, ...fileURLs]
      }));
    }
  }

  deleteUploadedPhotos = (index: number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.catalougePhotos];
      const updatedPreviews = [...prevState.uploadedCatalougePhotos];

      updatedFiles.splice(index, 1);
      updatedPreviews.splice(index, 1);

      return {
        catalougePhotos: updatedFiles,
        uploadedCatalougePhotos: updatedPreviews,
      };
    });
  };

  handleRefCall = () => {
    if (this.imgRef.current) {
      this.imgRef.current.click();
    }
  }
  
  handleDescriptionText = (features : any, catalogueType: string) => {
    if(catalogueType === "Car") {
      const carDescriptionFeature = features.find((feature: any) => feature.title === "car_description");
      const carDescription = carDescriptionFeature ? carDescriptionFeature.value : "";
      return carDescription
    } else {
      const homeDescriptionFeature = features.find((feature: any) => feature.title === "home_description");
      const homeDescription = homeDescriptionFeature ? homeDescriptionFeature.value : "";
      return homeDescription
    }
    
  }


  // Customizable Area End
}

// Customizable Area End