// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    styled,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput
} from "@material-ui/core"
import { homeLogoIcon, bgImageIcon, handIcon } from "./assets";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import LogInController, {
    Props,
  } from "./LoginScreenController";


class LogInScreen extends LogInController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
        <div>
            <AppHeader navigation={this.props.navigation} />

            <MainWrapper>
                <Grid container spacing={5}>
                    <Grid item md={6} sm={12}>
                        <Box>
                           <img src={bgImageIcon} alt="bgImageIcon" />
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <Wrapper>
                            <Box style={ {margin: "50px 0 25px 0"}}>
                                <img src={homeLogoIcon} alt="logo" />
                            </Box>

                            <Box>
                                <Box style={{ display: "flex"}}>
                                    <Typography className="headerTitle">Welcome Back to Roam</Typography>
                                    <img style={{ height : 40}} src={handIcon} />
                                </Box>
                                <form data-test-id="loginForm" onSubmit={this.handleLogIn}>
                                    <Typography className="labelText">Email</Typography>
                                    <OutlinedInput
                                        type="email"
                                        data-test-id="email"
                                        name="email"
                                        value={this.state.loginFormData.email}
                                        onChange={this.handleChangeForLogin}
                                        placeholder="Email"
                                        fullWidth
                                        className="inputFields" />
                                    {this.state.loginErrorFields.email && <div style={{ color: "red", marginBottom: "15px" }}>Please enter email address</div>}


                                    <Typography className="labelText">Password</Typography>
                                    <OutlinedInput
                                        type={this.state.isPasswordVisible ? "text" : "password"}
                                        name="password"
                                        data-test-id="password"
                                        value={this.state.loginFormData.password}
                                        onChange={this.handleChangeForLogin}
                                        placeholder="Password"
                                        fullWidth
                                        className="inputFields"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                >
                                                    {this.state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }

                                    />
                                    {this.state.loginErrorFields.password && <div style={{ color: "red" }}>Please enter password</div>}

                                    <br /><br />
                                    <Typography className="forgotPasswordText">Forgot Password?</Typography>

                                    <Box className="btnBox">
                                        <Button type="submit" fullWidth className="logInbtn"> Login</Button>
                                        <Button data-test-id="nav" onClick={() => this.navigateToSignUpPage()} fullWidth className="signUpBtn"> Sign-up</Button>
                                    </Box>

                                </form>
                            </Box>
                        </Wrapper>
                    </Grid>
                </Grid>
            </MainWrapper>

            <Footer />
        </div>
    );
    // Customizable Area End
  }
}

export default LogInScreen;

const MainWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto"
})

const Wrapper = styled(Box) ({

    "& .headerTitle" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '36.4px',
        textAlign: 'left',
        marginBottom: 20
    },

    "& .labelText" :  {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        textAlign: 'left'
    }, 

    "& .inputFields" : {
        height: '56px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid transparent',
        opacity: '0px',
        margin: "10px 0",
        boxShadow: "0px 4px 5px 0px #E7E7E7"
    }, 
    "& .forgotPasswordText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'end',
        color: "#3173E1",
    },
    "& .logInbtn": {
        height: "56px",
        color: "white",
        background: "#3173E1",
        borderRadius: "8px",
        textTransform: "none"
    },
    "& .signUpBtn" : {
        height: "56px",
        textTransform: "none",
        color: "#3173E1",
        background: "white",
        borderRadius: "8px",
        border: "1px solid #3173E1"
    },
    "& .btnBox" : {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "20px 0"
    },
    "& .MuiOutlinedInput-input": {
        padding: "12px 14px"
    }
})

// Customizable Area End