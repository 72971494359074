// Customizable Area Start
import React from "react";

import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";

import {
  Box,
  styled,
  Tabs,
  Tab,
  Grid,
  Typography,
  Button,
  Divider,
  OutlinedInput,
  Card,
  Dialog,
  IconButton,
} from "@material-ui/core"

import { editPenIcon, calenderIcon, starIcon, Speed, ShieldKey, Fuel, CircleCross, Paper, Form } from "./assets";
import { Verified1, flash, speed } from "../../../blocks/productdescription/src/assets";
import Loader from "../../../components/src/Loader.web";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StarIcon from '@material-ui/icons/Star';
import ReservationChat from "../../../blocks/chat/src/ReservationChat.web";

import ReservationDetailsController, {
  Props,
} from "./ReservationDetailsController";
import { truthyValue } from "../../../components/src/asset";
import { AntSwitch } from "../../../components/src/FilterModal.web";


class ReservationDetails extends ReservationDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start

    const milegeBoxUI = () => {
      return (
        <Box className="mileageUiBox" >
          <Box style={{ borderRadius: 12, backgroundColor: '#EAECF0', padding: 32, display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
            <img src={Speed} alt="Speed" />
            <Typography className="insuranceTitles">300 mile</Typography>
            <Typography className="insuranceDescription">$3.50/additional mile</Typography>
          </Box>
          <Box style={{ borderRadius: 12, backgroundColor: '#EAECF0', padding: 32, margin: '0 8px', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
            <img src={ShieldKey} alt="Shield" />
            <Typography className="insuranceTitles">Full Coverage</Typography>
            <Typography className="insuranceDescription">Via Loyds of London</Typography>
          </Box>
          <Box style={{ flex: 1, backgroundColor: '#EAECF0', borderRadius: 12, display: 'flex', padding: 32, flexDirection: 'column', alignItems: 'center' }}>
            <img src={Fuel} alt="fuel" />
            <Typography className="insuranceTitles">Fuel Policy</Typography>
            <Typography className="insuranceDescription">Premium only (91+)</Typography>
          </Box>
        </Box>
      )
    }

    return (
      <div>
        <AppHeader activeLink="Reservation" navigation={this.props.navigation} />
        {this.state.loading ? <Loader loading={true} /> :
          <InfoWrapper>

            <Box>
              <Grid container spacing={3} className="mainGrid">
                <Grid item md={8}>
                  <Box className="breadcrumbsBox">
                    <Typography className="breadcrumbText" onClick={this.navigateToReservationPage} data-test-id="bredcrumbOne">Reservations</Typography>
                    <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                    <Typography className="breadcrumbText"><span className="colorText">{truthyValue(this.state.getReservationInfo.attributes.catalogue_name)}</span></Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: 10 }}>
                    <img src={this.handleImageUrl(this.state.getReservationInfo)} style={{ width: 132, height: 114, borderRadius: "12px" }} />
                    <Box>
                      <Typography className="catalogeName">{truthyValue(this.state.getReservationInfo.attributes.catalogue_name)}</Typography>
                      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                        <img src={starIcon} />
                        <Typography>  4.8 <span>44 reviews</span></Typography>
                      </div>

                    </Box>
                  </Box>
                  <Box style={{ marginTop: "30px" }}>
                    <Tabs
                      className="mainTabs"
                      value={this.state.tabValue}
                      onChange={this.handleTabChanges}
                      data-test-id="tabs"
                    >
                      <Tab label="Car Details" className="tabText activeTabColor"></Tab>
                      <Tab label="Trip Info" className="tabText"></Tab>
                      <Tab label="Messages" className="tabText"></Tab>
                      <Tab label="Receipt" className="tabText"></Tab>
                    </Tabs>
                    <Box className="countBox">
                      <Typography className="countText">{this.state.unreadMsgCount}</Typography>
                    </Box>
                  </Box>

                  {this.state.tabValue === 0 &&
                    <Box>

                      <Box className="descriptionTextBox">
                        <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
                          Description
                        </Typography>
                        <Box style={{ fontSize: "16px", marginBottom: "32px" }}>
                          <Typography style={{ letterSpacing: 'normal', color: '#475467', marginBottom: "12px", }} >
                            Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla. Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus.
                          </Typography>
                          <Typography>
                            <ul>
                              <li style={{ color: '#475467' }}>
                                Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id.Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.
                              </li>
                              <li style={{ color: '#475467' }}>
                                Non pellentesque congue eget consectetur turpis.
                              </li>
                              <li style={{ color: '#475467' }}>
                                Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.            </li>
                            </ul>
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="carSpecificationBox">
                        <Typography style={{ fontSize: 20, fontWeight: 600, letterSpacing: 'normal', marginBottom: "32px", color: '#23395D' }}>
                          Car Specification
                        </Typography>
                        <Box className="specification-box">
                          <Box style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white' }}>
                            <Typography style={{ color: '#475467', fontSize: 16, display: "flex", gap: 6, alignItems: "center" }}><img src={flash}></img>Engine/Cylinders:</Typography>
                            <Typography style={{ color: '#101828', fontSize: 16 }}>v12</Typography>
                          </Box>
                          <Box style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white' }}>
                            <Typography style={{ color: '#475467', fontSize: 16, display: "flex", gap: 6, alignItems: "center" }}><img src={flash}></img>Max Torque:</Typography>
                            <Typography style={{ color: '#101828', fontSize: 16 }}>603nm</Typography>
                          </Box>
                          <Box style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white' }}>
                            <Typography style={{ color: '#475467', fontSize: 16, display: "flex", gap: 6, alignItems: "center" }}><img src={flash}></img>Max Power:</Typography>
                            <Typography style={{ color: '#101828', fontSize: 16 }}>630ps</Typography>
                          </Box>
                          <Box style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white' }}>
                            <Typography style={{ color: '#475467', fontSize: 16, display: "flex", gap: 6, alignItems: "center" }}><img src={speed}></img>Top Speed:</Typography>
                            <Typography style={{ color: '#101828', fontSize: 16 }}>201 mph</Typography>
                          </Box>
                          <Box style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white' }}>
                            <Typography style={{ color: '#475467', fontSize: 16, display: "flex", gap: 6, alignItems: "center" }}><img src={speed}></img>Acceleration:</Typography>
                            <Typography style={{ color: '#101828', fontSize: 16 }}>0-60mph/3.5sec</Typography>
                          </Box>
                          <Box style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white' }}>
                            <Typography style={{ color: '#475467', fontSize: 16, display: "flex", gap: 6, alignItems: "center" }}><img src={flash}></img>Transmissions:</Typography>
                            <Typography style={{ color: '#101828', fontSize: 16 }}>8 Speed touchtronic || </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="moreImagesTextBox">
                        <Typography style={{ fontSize: 20, fontWeight: 600, letterSpacing: 'normal', marginBottom: "32px", color: '#23395D' }}>
                          More Images
                        </Typography>
                        <Box className="inner-img-box">
                          <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                            {this.state.getReservationInfo.attributes.images.map((imgUrls: any) => {
                              return (
                                <Grid item md={6}>
                                  <img style={{ height: 175, width: 395, borderRadius: 12 }} src={imgUrls.url}></img>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Box>
                      </Box>

                      <Box className="milageBox">
                        <Box>
                          <Typography style={{ fontSize: 20, fontWeight: 600, marginTop: "32px", color: '#23395D', margin: "20px 0" }}>
                            Mileage/Insurance Coverage
                          </Typography>
                          {milegeBoxUI()}
                        </Box>
                      </Box>

                      <Box className="aboutHostBox">
                        <Box style={{ marginTop: 32 }}>
                          <Card style={{ boxShadow: 'none' }}>
                            <Box style={{ display: "flex", gap: 15 }}>
                              <img src={truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.host_profile_image.url)} style={{ width: 58, height: 58, borderRadius: '100%' }} alt="" />
                              <Box style={{ display: "flex", flexDirection: "column" }}>
                                <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, fontWeight: 600 }}>
                                  {truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.hosted_by.full_name)}
                                </Typography>
                                <Box style={{ display: "flex", gap: 29, }}>
                                  <Typography>{truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.hosted_by.host_trip_completed)} trips</Typography>
                                  <Typography>Host since {truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.hosted_by.joined)}</Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", gap: 14 }}>
                              <Box style={{ display: "flex", gap: 8, alignItems: 'center' }}>
                                <StarIcon style={{ width: "16px", height: "16px" }} />
                                <Typography>512 reviews</Typography>
                              </Box>
                              <Box style={{ display: "flex", gap: 8, alignItems: 'center' }}>
                                <img style={{ width: "16px" }} src={Verified1}></img><Typography>Identity Verified</Typography>
                              </Box>
                            </Box>
                            <Box style={{ marginTop: "24px" }}>
                              <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: "16px", marginBottom: "12px", color: '#23395D' }}>About Host</Typography>
                              <Typography>
                                Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla. Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus.
                              </Typography>
                            </Box>
                            <Box className="registration-parent-box" style={{ display: "flex", gap: 12, width: "100%", marginTop: 24 }}>
                              <Box className="registration-box" style={{ padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1 }}>
                                <Typography className="reg-first" variant="body1" style={{ color: "#475467" }}>Registration number:</Typography>
                                <Typography variant="body1" style={{ color: "#475467", fontSize: "18px", lineHeight: "28px", fontWeight: 500 }}>PCC-000917</Typography>
                              </Box>
                              <Box className="registration-box" style={{ padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1 }}>
                                <Typography variant="body1" style={{ color: "#475467" }}>Response rate:</Typography>
                                <Typography variant="body1" style={{ color: "#475467", fontSize: "18px", lineHeight: "28px", fontWeight: 500 }}>100%</Typography>
                              </Box>
                              <Box className="registration-box" style={{ padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1 }}>
                                <Typography variant="body1" style={{ color: "#475467" }}>Response time:</Typography>
                                <Typography variant="body1" style={{ color: "#475467", fontSize: "18px", lineHeight: "28px", fontWeight: 500 }}>within an hour</Typography>
                              </Box>
                            </Box>
                          </Card>
                        </Box>
                      </Box>

                      <Box className="driversBox">
                        <Typography style={{ fontSize: 20, fontWeight: 600, letterSpacing: 'normal', marginBottom: "18px", color: '#23395D', marginTop: "32px" }}>
                          Drivers
                        </Typography>

                        <Box style={{ padding: 20, border: "1px solid #EAECF0", borderRadius: "12px" }}>
                          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px", background: "#EAECF0", borderRadius: "12px" }}>
                            <Box style={{ display: "flex", gap: "15px" }}>
                              <img src={this.state.renterInfo.renterProfile} style={{ width: 58, height: 58, borderRadius: '100%' }} alt="" />
                              <Box>
                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                  <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, fontWeight: 600 }}>
                                    {this.state.renterInfo.renterName}
                                  </Typography>
                                  <Box style={{ display: "flex", gap: 29, }}>
                                    <Typography>{this.state.renterInfo.renterTrips} trips</Typography>
                                    <Typography>since {this.state.renterInfo.renterJoined}</Typography>
                                  </Box>
                                </Box>
                              </Box>

                            </Box>
                            <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                              <AntSwitch checked />
                              <Typography className="primaryDriver"> Primary Driver</Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Box>
                    </Box>
                  }
                  {this.state.tabValue === 1 &&
                    <Box>
                      <Box className="descriptionTextBox">
                        <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
                          Mileage/Insurance Coverage
                        </Typography>
                      </Box>
                      {milegeBoxUI()}
                      <Box style={{ margin: '32px 0' }}>
                        <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
                          Fuel Policy
                        </Typography>
                        <Box style={{ gap: 12, borderRadius: 12, border: '1px solid #EAECF0', padding: 16 }}>
                          <Box style={{ display: 'flex' }}>
                            <img src={Fuel} alt="fuel" width={20} height={20} />
                            <Typography style={{ marginLeft: 6, fontFamily: 'Inter', fontWeight: 600, fontSize: 16, color: '#23395D' }}>Fuel Type: Premium Only (91+)</Typography>
                          </Box>
                          <Box style={{ marginTop: 16, marginBottom: 6 }}>
                            <Typography style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 16, color: '#475467' }}>Return with the same fuel level.</Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 14, color: '#475467' }}>A $10 convience fee + the fuel replacement cost will be charged.</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ margin: '32px 0' }}>
                        <Typography style={{ marginTop: "24px", letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', }}>
                          Trip Rules & Guidelines
                        </Typography>
                        <Box style={{ borderRadius: 12, gap: 12, border: '1px solid #EAECF0', padding: 16 }}>
                          <Box style={{ display: 'flex' }}>
                            <img src={CircleCross} alt="circleCross" width={20} height={20} />
                            <Typography style={{ fontFamily: 'Inter', marginLeft: 6, fontWeight: 600, fontSize: 16, color: '#23395D' }}>Free Cancellation (24-hour prior)</Typography>
                          </Box>
                          <Box style={{ marginBottom: 6, marginTop: 16, }}>
                            <Typography style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 16, color: '#475467' }}>Return with the same fuel level.</Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 14, color: '#475467' }}>Receive a full refund if you cancel before October 21, 10:30 AM. (Local time of car)</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ margin: '32px 0' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 600, letterSpacing: 'normal', marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
                          Cancellation  Policy
                        </Typography>
                        <Box style={{ gap: 12, borderRadius: 12, border: '1px solid #EAECF0', padding: 16 }}>
                          <Box style={{ display: 'flex' }}>
                            <img alt="circleCross" src={CircleCross} width={20} height={20} />
                            <Typography style={{ marginLeft: 6, fontFamily: 'Inter', fontWeight: 600, fontSize: 16, color: '#23395D' }}>Free Cancellation (24-hour prior)</Typography>
                          </Box>
                          <Box style={{ marginTop: 16, marginBottom: 6 }}>
                            <Typography style={{ color: '#475467', fontFamily: 'Inter', fontSize: 16, fontWeight: 500, }}>Return with the same fuel level.</Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 400, color: '#475467' }}>Receive a full refund if you cancel before October 20, 10:30 AM. (Local time of car)</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  }

                  {
                    this.state.tabValue === 2 &&
                    <Box>
                      <ReservationChat
                        receiverId={this.state.receiverId}
                        navigation={this.props.navigation}
                        id="ChatBlock"
                        updateCount={(count) => this.setCountFn(count)} />
                    </Box>
                  }
                  {this.state.tabValue === 3 &&
                    <Box>
                      <Box style={{ padding: 16 }}>
                        <Typography style={{ fontSize: 20, fontWeight: 600, letterSpacing: 'normal', marginBottom: 18, color: '#23395D', marginTop: 24 }}>
                          Documents
                        </Typography>
                        <Box style={{ display: 'flex' }}>
                          <Box style={{ padding: 16, border: '1px solid #EAECF0', borderRadius: 12, marginRight: 16 }}>
                            <Box style={{ display: 'flex', marginBottom: 16 }}>
                              <img src={Paper} alt="Paper" />
                              <Typography style={{ fontWeight: 600, padding: '4px 0 0 6px', fontSize: 16 }}>Car Sharing Agreement</Typography>
                            </Box>
                            <Typography style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}>For law enforcement and legal needs.</Typography>
                            <Box>
                              <img src={Form} width={348} height={460} />
                            </Box>
                          </Box>
                          <Box style={{ padding: 16, border: '1px solid #EAECF0', borderRadius: 12 }}>
                            <Box style={{ display: 'flex', marginBottom: 16 }}>
                              <img src={Paper} alt="Paper" />
                              <Typography style={{ fontWeight: 600, fontSize: 16, padding: '4px 0 0 6px' }}>Incident Information Card</Typography>
                            </Box>
                            <Typography style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}>For proof of liability insurance.</Typography>
                            <Box>
                              <img src={Form} width={348} height={460} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ margin: '32px 0' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 600, letterSpacing: 'normal', marginBottom: 18, color: '#23395D', marginTop: 24 }}>
                          Insurance & Protection
                        </Typography>
                        <Box style={{ borderRadius: 12, gap: 12, border: '1px solid #EAECF0', padding: 16 }}>
                          <Box style={{ display: 'flex' }}>
                            <img src={ShieldKey} alt="shield" width={20} height={20} />
                            <Typography style={{ marginLeft: 6, fontWeight: 600, fontSize: 16, color: '#23395D' }}>Full Coverage Insurance Plan</Typography>
                          </Box>
                          <Box style={{ marginBottom: 6, marginTop: 16, }}>
                            <Typography style={{ fontWeight: 500, fontSize: 16, color: '#475467' }}>Out of pocket maximum: $1000</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  }
                </Grid>
                <Grid item md={4}>
                  <Box className="bgGray">
                    <Box className="boxDiv">
                      <Typography className="startStripText">Your Trip Starts in : <span className="colorText">{this.handleStartTimeHours(truthyValue(this.state.getReservationInfo.attributes.start_time))}</span></Typography>
                      <Box style={{ display: "flex", gap: 10 }}>
                        <Button fullWidth className="modifyBtn" startIcon={<img src={editPenIcon} />}>Modify Trip</Button>
                        <Button fullWidth data-test-id="checkOutBtn" className="checkOutBtn" onClick={this.openCheckOutModal} >Start Checkout</Button>
                      </Box>
                    </Box>
                    <Box className="boxDiv">
                      <Typography className="summaryText">Reservation Summary</Typography>
                      <Divider style={{ margin: "10px 0" }} />

                      <Box style={{ margin: "15px 0" }}>
                        <Typography className="labelText">Pick-up Location</Typography>
                        <OutlinedInput readOnly disabled fullWidth className="inputFields" value={"New York"} />
                      </Box>

                      <Box style={{ margin: "15px 0" }}>
                        <Typography className="labelText">Pick-up</Typography>
                        <OutlinedInput readOnly disabled fullWidth endAdornment={<img src={calenderIcon} />} className="inputFields" value={localStorage.getItem("reservationStartDate")} />
                      </Box>

                      <Box style={{ margin: "15px 0" }}>
                        <Typography className="labelText">Drop-off Location</Typography>
                        <OutlinedInput readOnly disabled fullWidth className="inputFields" value={"New York"} />
                      </Box>

                      <Box style={{ margin: "15px 0" }}>
                        <Typography className="labelText">Drop-off</Typography>
                        <OutlinedInput readOnly disabled fullWidth endAdornment={<img src={calenderIcon} />} className="inputFields" value={localStorage.getItem("reservationEndDate")} />
                      </Box>

                    </Box>
                    <Box className="boxDiv">
                      <Typography className="summaryText">Pricing Breakdown</Typography>
                      <Divider style={{ margin: "10px 0" }} />

                      <Box className="priceBox">
                        <Box>
                          <Typography className="headerOneText">Rental Price</Typography>
                          <Typography className="headerTwoText">$620/day <span className="colorText"> (x7 days) </span></Typography>
                        </Box>
                        <Box>
                          <Typography className="headerOneText">$4340</Typography>
                        </Box>
                      </Box>

                      <Box className="priceBox">
                        <Box>
                          <Typography className="headerOneText">3 + days discount</Typography>
                          <Typography className="headerTwoText">Extended trip discount <span className="colorText"> (5%) </span></Typography>
                        </Box>
                        <Box>
                          <Typography className="headerOneText">-$217</Typography>
                        </Box>
                      </Box>
                      <Box className="priceBox">
                        <Box>
                          <Typography className="headerOneText">Refundeable Deposit</Typography>
                          <Typography className="headerTwoText">Refunded by  <span className="colorText"> Oct 14th </span></Typography>
                        </Box>
                        <Box>
                          <Typography className="headerOneText">$500</Typography>
                        </Box>
                      </Box>
                      <Divider style={{ margin: "10px 0" }} />
                      <Box className="priceBox">
                        <Box>
                          <Typography className="headerOneText">Total Price Due</Typography>
                          <Typography className="headerTwoText">$500 Refunded by <span className="colorText"> Oct 14th </span></Typography>
                        </Box>
                        <Box>
                          <Typography className="headerOneText">$4623</Typography>
                        </Box>
                      </Box>
                      <Box className="headerTwoText">
                        *Your total rent amount will be calculated depending on the pick-up and drop-off dates.
                      </Box>
                    </Box>

                    <Box className="boxDiv">
                      <Box style={{ display: "flex", gap: 10 }}>
                        <img src={truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.host_profile_image.url)} style={{ width: 58, height: 58, borderRadius: '100%' }} alt="" />
                        <Box>
                          <Typography>
                            {truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.hosted_by.full_name)}
                          </Typography>
                          <Typography>{truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.hosted_by.host_trip_completed)} trips <span style={{ marginLeft: 5 }}> Host since {truthyValue(this.state.getReservationInfo.attributes.hosted_by.data.attributes.hosted_by.joined)} </span></Typography>
                        </Box>
                      </Box>

                      <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                        <Button fullWidth color="primary" variant="outlined" style={{ textTransform: "none", borderRadius: "12px" }}>View Profile</Button>
                        <Button fullWidth variant="outlined" style={{ textTransform: "none", borderRadius: "12px" }}>Contact</Button>

                      </Box>

                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </InfoWrapper>
        }

        <DialogWrapper
          open={this.state.checkOutModal}
          onClose={this.closeCheckOutModal}
          fullWidth
          data-test-id="closeModal"
          maxWidth="sm"
        >

          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
            <Typography className="startTripHeader">Start Trip</Typography>
            <IconButton style={{ marginRight: 20 }} edge="end" color="inherit" data-test-id="closeIcon" onClick={this.closeCheckOutModal} aria-label="close">
              <CloseIcon style={{ color: "#64748B" }} />
            </IconButton>
          </Box>
          <Box textAlign={"center"} style={{ margin: "20px 0" }}>
            <Typography className="startTripInfo">
              Your trip is starting soon. To start the trip, please
            </Typography>
            <Typography className="startTripInfo">
              follow the check-out steps.
            </Typography>
            <Button className="checkOutBtnModal" data-test-id="navToCheckout" onClick={this.navigateToCheckout}>Start Checkout</Button>
          </Box>
        </DialogWrapper>

        <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default ReservationDetails;

const InfoWrapper = styled(Box)({
  maxWidth: "1220px",
  margin: "40px auto",
  position: "relative",
  padding: "25px",
  "& .mainGrid": {
    "@media(max-width:960px)": {
      "&.MuiGrid-container": {
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: "center !important"
      }
    }
  },
  "& .carSpecificationBox": {
    padding: "20px"
  },
  "& .specification-box": {
    display: 'grid',
    gap: 12,
    gridTemplateColumns: 'auto auto auto',
    marginBottom: 32,
    "@media(max-width:550px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width:450px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    }
  },
  "& .mileageUiBox": {
    display: 'flex',
    justifyContent: 'space-between',
    "@media(max-width:550px)": {
      display: 'grid',
      gridTemplateColumns: "repeat(1, 1fr)",
      gridGap: "15px"
    }
  },
  "& .insuranceTitles": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    marginTop: 16,
    marginBottom: 3,
    color: '#101828'
  },
  "& .insuranceDescription": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#475467'
  },
  "& .primaryDriver": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#3173E1'
  },
  "& .catalogeName": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '38px',
    textAlign: 'left',
    color: '#23395D'
  },
  "& .tabText": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textTransform: "none",
    color: "#475467"
  },
  "& .mainTabs": {
    borderBottom: "1px solid #E2E8F0",
  },
  "& .mainTabs .Mui-selected": {
    color: "#3173E1",
    borderBottom: "none"
  },
  "& .mainTabs .MuiTabs-indicator": {
    backgroundColor: "#3173E1"
  },
  "& .countBox": {
    background: "#EAECF0",
    borderRadius: "16px",
    padding: "2px 10px",
    position: "absolute",
    left: "450px",
    top: "220px"
  },
  "& .countText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054'
  },

  "& .bgGray": {
    background: "#EAECF0",
    padding: "20px",
  },
  "& .startStripText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: 'left',
    color: "#23395D"
  },
  "& .colorText": {
    color: "#3173E1",
  },
  "& .boxDiv": {
    padding: "20px",
    background: "white",
    borderRadius: 12,
    marginBottom: "20px"
  },
  "& .modifyBtn": {
    height: "40px",
    border: "1px solid #D0D5DD",
    borderRadius: 30,
    marginTop: "15px",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
    textAlign: 'left',
    color: "#475467"
  },
  "& .checkOutBtn": {
    height: "40px",
    fontFamily: 'Plus Jakarta Sans',
    marginTop: "15px",
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
    borderRadius: 30,
    textAlign: 'left',
    color: "white",
    background: "#3173E1"
  },
  "& .inputFields": {
    height: "40px",
    borderRadius: "30px",
    padding: "10px 14px",
  },
  "& .MuiOutlinedInput-input": {
    padding: 0
  },
  "& .summaryText": {
    ontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#475467'
  },
  "& .labelText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#667085'
  },
  "& .priceBox": {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0"
  },
  "& .headerOneText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#344054',
    paddingBottom: "5px"
  },
  "& .headerTwoText": {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 400,
    fontSize: '12px',
    textAlign: 'left',
    color: '#667085'
  },
  "& .breadcrumbText": {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'left',
    color: '#64748B',
    cursor: "pointer"
  },
  "& .breadcrumbsBox": {
    display: "flex",
    gap: "10px",
    marginBottom: "30px",
    alignItems: "center"
  },
})

const DialogWrapper = styled(Dialog)({
  "& .startTripHeader": {
    color: "#23395D",
    fontFamily: 'Plus Jakarta Sans',
    marginLeft: "240px",
    fontSize: '24px',
    fontWeight: 600,
  },
  "& .startTripInfo": {
    color: "#475467",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 500,
  },
  "& .checkOutBtnModal": {
    height: "40px",
    fontFamily: 'Plus Jakarta Sans',
    marginTop: "30px",
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
    borderRadius: 30,
    textAlign: 'left',
    color: "white",
    background: "#3173E1",
    width: "200px"
  },
})


// Customizable Area End