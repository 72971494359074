// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { apiCalling, generateRandomNumberPlate, truthyValue } from "../../../components/src/asset";
import { bluetoothIcon, appleIcon, keyIcon, seatIcon,androiadIcon, cameraIcon, bikeIcon, alarmIcon } from "./assets";
import { toast } from "react-toastify";
import { decodeVIN } from 'universal-vin-decoder';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface VehicleInfo {
  country: string;
  manufacturer: string;
  modelYear: string;
  region: string;
}

interface S {
  // Customizable Area Start  
  activeStep : number,
  carVIN: string,
  carVINError: boolean,
  addPhotos: boolean,
  carPhotos: File[],
  uplodedCarPhotosURL: string[],
  carFeatures: any[],
  carDescription: string,
  advancedNoticeId : number,
  minimumTripId: number,
  maximumTripId: number,
  catogoryIdCar: number | string,
  carName: string,
  editCarName : boolean,
  carVINErrMsg : string,
  vechileInfo : VehicleInfo
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddCarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileRef: React.RefObject<HTMLInputElement>
  createCarApiCallId :string = ""
  getCarCatogoryIdApiCallId:  string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      activeStep: 1,
      carVIN: "",
      carVINError: false,
      addPhotos: true,
      carPhotos: [],
      uplodedCarPhotosURL: [],
      carFeatures: this.carFeatures,
      carDescription: "",
      advancedNoticeId: 2,
      minimumTripId: 2,
      maximumTripId: 5,
      catogoryIdCar: "",
      carName: "Lamborghini Urus (2021)",
      editCarName: false,
      carVINErrMsg: "",
      vechileInfo: {
        country: "",
        manufacturer: "",
        modelYear: "",
        region: ""
      }
    };
    this.fileRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

       if( apiRequestCallId === this.createCarApiCallId) {
         this.createCarAPIResponse(responseJson)
      } else if (apiRequestCallId === this.getCarCatogoryIdApiCallId) {
         this.handleResponseForCarCatogoriesId(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getCatogoriyId()
  }

  handleResponseForCarCatogoriesId =(responseJson: any) => {
    if(responseJson && responseJson.length > 0) {
      const carId = responseJson.find((item: any) => item.name === "Car").id
      this.setState({ catogoryIdCar: carId})
    }
  } 


  carFeatures = [
    {id : 1, icon: bluetoothIcon, label: "Bluetooth", check: false},
    {id : 2, icon: appleIcon, label: "AppleCanPlay", check: false},
    {id : 3, icon: keyIcon, label: "Keyless entry", check: false},
    {id : 4, icon: seatIcon, label: "Heated seats", check: false},
    {id : 5, icon: androiadIcon, label: "Android Auto", check: false},
    {id : 6, icon: cameraIcon, label: "Backup Camera", check: false},
    {id : 7, icon: bikeIcon, label: "Bike rack", check: false},
    {id : 8, icon: alarmIcon, label: "Blindspot Warning", check: false},
  ]

  stepperInfo = [
    { id : 1, label: "Enter VIN", activeKey : 1},
    { id : 2, label: "About Your Car", activeKey : 2},
    { id : 3, label: "Listing Set-up", activeKey : 3},
    { id : 4, label: "Car Photos", activeKey : 4},
    { id : 5, label: "Link Payouts", activeKey : 5},
    { id : 6, label: "Success", activeKey : 6}
  ]

  advanceNoticeData = [
    { id: 1, label: "1 hour", name: "advancedNotice",isRecommand : false},
    { id: 2, label: "2 hours", name: "advancedNotice",isRecommand : true},
    { id: 3, label: "3 hours", name: "advancedNotice",isRecommand : false},
    { id: 4, label: "6 hours", name: "advancedNotice",isRecommand : false},
    { id: 5, label: "12 hours", name: "advancedNotice",isRecommand : false},
    { id: 6, label: "1 day", name: "advancedNotice",isRecommand : false},
  ]

  minimumTripDuration = [
    { id: 1, label: "1 hour", name: "minimumTripDuration", isRecommand : false},
    { id: 2, label: "1 day", name: "minimumTripDuration", isRecommand : true},
    { id: 3, label: "2 days", name: "minimumTripDuration", isRecommand : false},
    { id: 4, label: "3 days", name: "minimumTripDuration", isRecommand : false},
    { id: 5, label: "5 days", name: "minimumTripDuration", isRecommand : false},
    { id: 6, label: "2-day min for weekend", name: "minimumTripDuration", isRecommand : false},
  ]

  maximumTripDuration = [
    { id: 1, label: "5 days", name: "maximumTripDuration", isRecommand : false},
    { id: 2, label: "2 weeks", name: "maximumTripDuration", isRecommand : false},
    { id: 3, label: "1 Month", name: "maximumTripDuration", isRecommand : false},
    { id: 4, label: "3 Months", name: "maximumTripDuration", isRecommand : false},
    { id: 5, label: "Any", name: "maximumTripDuration", isRecommand : true},
  ]

  handleChangeforAdvancedNotice = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ advancedNoticeId : parseInt(event.target.value)})
  }

  handleChangeforMinimumTrip = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ minimumTripId : parseInt(event.target.value)})
  }

  handleChangeforMaximumTrip = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ maximumTripId : parseInt(event.target.value)})
  }

  createCarAPIResponse = (responseJson : any) => {
    if(responseJson && responseJson.catalogue) {
      this.handleNextStep();
   } else {
      toast.error("Something went wrong. Please Try again later")
   }
  }

  navigateToListPage= () => {
    this.props.navigation.navigate("HostDashboard")
  }

  handleCheckboxChange = (value:number) => {
    this.setState(prevState => ({
      carFeatures: prevState.carFeatures.map(feature =>
        feature.id === value ? { ...feature, check: !feature.check } : feature
      ),
    }));
  }

  handleChangeForCarDescriptionStep2 = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ carDescription : event.target.value})
  }

  handleNextStep = () => {
    this.setState({ activeStep : this.state.activeStep+1})
  }

  handlePreviousStep = () => {
    this.setState({ activeStep : this.state.activeStep-1})
  }

  handleVerifyVIN = () => {
     const response = decodeVIN(this.state.carVIN)

     if(response.isValid && response.info) {
       const carInfo = {
         country: truthyValue(response.info.country),
         manufacturer: truthyValue(response.info.manufacturer),
         modelYear: truthyValue(response.info.modelYear),
         region: truthyValue(response.info.region)
       }

       this.setState({ vechileInfo : carInfo, carName : carInfo.manufacturer})
       this.handleNextStep()
       
     } else {
       let errMsg = "Please Enter Valid VIN Number"
        this.setState({ carVINError : true, carVINErrMsg: errMsg})
     }
  }

  handleVINTexField = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      if(inputValue.trim() === "") {
         this.setState({ carVINError : true})
      } else { 
        this.setState({ carVINError : false})
      }

      this.setState({ carVIN : inputValue})

  }

  handleToggleAddPhostosBtn = () => {
    this.setState({ addPhotos : false})
  }

  handleCarPhotosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        carPhotos: [...prevState.carPhotos, ...newFiles],
        uplodedCarPhotosURL: [...prevState.uplodedCarPhotosURL, ...fileURLs]
      }));
    }

  };

  handleDeleteUploadedPhotos = (index: number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.carPhotos];
      const updatedPreviews = [...prevState.uplodedCarPhotosURL];

      updatedFiles.splice(index, 1);
      updatedPreviews.splice(index, 1);

      return {
        carPhotos: updatedFiles,
        uplodedCarPhotosURL: updatedPreviews,
      };
    });
  };

  handleRefCall = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  }

  getCatogoriyId = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    
    this.getCarCatogoryIdApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.getCatogoriesApiEndPoint,
      method: configJSON.apiMethodTypeGet
    })
  }

  addCar = () => {

    if(this.state.carName.trim() === "" ) {
      toast.error("Please Enter Car Name in Step 2")
      return false
    }
    const header = {
      'token': localStorage.getItem("userToken")
    }

    const formData = new FormData();
    formData.append("catalogue[category_id]", this.state.catogoryIdCar.toString())    
    formData.append("catalogue[features][car_description]", this.state.carDescription)
    formData.append("catalogue[car_vin]", this.state.carVIN)
    formData.append("catalogue[car_name]", this.state.carName)
    formData.append("catalogue[name]", "Car")
    formData.append("type", "Car")
    this.state.carPhotos.forEach(value => {
      formData.append("catalogue[images][]", value);
    });
    formData.append("address[country]", "India")
    formData.append("address[latitude]", "22.719568")
    formData.append("address[longitude]", "75.857727")
    formData.append("address[city]", "bhopal")
    formData.append("address[pincode]", "462010")
    formData.append("address[address]", "Vijay nagar")
    formData.append("address[address_type]", "pickup_location")
    formData.append("catalogue[feature_attributes][bluetooth]", this.handleCarFeaturesForSubmit(1))
    formData.append("catalogue[feature_attributes][apple_car_play]", this.handleCarFeaturesForSubmit(2))
    formData.append("catalogue[feature_attributes][android_auto]", this.handleCarFeaturesForSubmit(5))
    formData.append("catalogue[feature_attributes][backup_camera]", this.handleCarFeaturesForSubmit(6))
    formData.append("catalogue[car_number_plate]", generateRandomNumberPlate())
    formData.append("catalogue[car_year]", this.state.vechileInfo.modelYear)
    formData.append("catalogue[status]","active")


   this.createCarApiCallId = apiCalling({
    header: JSON.stringify(header),
    endPoint: configJSON.createHomeApiEndpoint,
    method: configJSON.apiMethodTypePost,
    body: formData
   })
  }

  handleCarFeaturesForSubmit = (index : number) => {
    const keyCondition = this.state.carFeatures.find(itemVal => itemVal.id === index).check 
    return keyCondition ? "yes" : "no"
  }

  editableCarName = () => {
    this.setState({ editCarName : true})
  }

  handleChangeForCarName = (event: React.ChangeEvent<HTMLInputElement>) => {
     this.setState({ carName: event.target.value})
  }

  // Customizable Area End
}

// Customizable Area End