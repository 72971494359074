// Customizable Area Start
import React from "react";
import AddCarController, {
    Props,
  } from "./AddCarController";
import { Box, styled, LinearProgress, Button, Typography, OutlinedInput,TextField , TextareaAutosize, Grid, Checkbox, Radio, Divider } from "@material-ui/core";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web"
import { payoutIcon, imageIcons, congratsIcon, deleteIcon, editIcon, carLocationIcon } from "./assets"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


class AddCar extends AddCarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  handleAllStepper = (step : number) => {
    switch(step) {
      case 1:
        return this.step1()
      case 2:
        return this.step2()
      case 3:
        return this.step3()
      case 4:
        return this.step4()
      case 5:
        return this.step5()
      case 6:
        return this.step6()    
    }
  }

  handleSameBoxStep3 = (title : string) => {
    return (<Box style={{ display: "flex", alignItems: "center", padding: "24px 16px", gap: "5px", background: "#EAECF0" }}>
      <Typography className="descriptionText3">{title}</Typography>
      <HelpOutlineIcon />
    </Box>)
  }

  handleStepperCSS = (activeKey:number, activeState: number) => {
    if(activeKey === activeState) {
       return "currentstepperIcons"
    } else if( activeKey < activeState) {
      return "activestepperIcons"
    } else {
      return "stepperIcons"
    }
  }

  handleBackLink = () => {
    return (
      <Box style={{ display: "flex", gap: "5px" }}>
        <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />
        <Typography 
        data-test-id="backLink" 
        style={{
          color: "#667085",
          fontSize: '14px',
          fontFamily: 'Plus Jakarta Sans',
          marginTop: '-2px',
          cursor: "pointer"
        }}
          onClick={() => this.handlePreviousStep()}
        > Back</Typography>
      </Box>
    )
  }

  handleHeaderText = (headerText: string) => {
    return <Typography className="headerText">{headerText}</Typography>
  }

  handleBackAndNextBtn = () => {
    return(
      <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
          <Button data-test-id="backBtn" onClick={() => this.handlePreviousStep()} className="backBtn">Back</Button>
          <Button data-test-id="nextBtn" onClick={() => this.handleNextStep()} className="nextBtn">Continue</Button>
      </Box>
    )
  }
  
  step1 = () => {
    return (
      <Stepper1>
        <Grid container spacing={3}>
          <Grid item md={8}>

            {this.stepperHeader()}
            {this.progressBarDiv()}

          </Grid>
        </Grid>
       
        {this.handleHeaderText("Start your jouney")}
          <Box>
             <Typography className="enterVINLabel"> Enter VIN</Typography>
             <OutlinedInput
              placeholder="Enter vin"
              className="vinBox"
              value={this.state.carVIN}
              data-test-id="vinNumber"
              onChange={this.handleVINTexField}
              />
              {this.state.carVINError && <div style={{ color: "red"}}>{this.state.carVINErrMsg}</div>}
          </Box>
          <br />
          <small style={{ color: "#475467", fontSize: "14px"}}>We’ll use your VIN (Vehicle Identification Number) to identify your specific car. It can be found in a few areas:</small>
          <br/>

        <Box style={{ display:"flex", margin: "25px 0", gap: "10px"}}>
          <Box style={{ display: "flex", gap: "15px"}}>
            <div className="countDown">1</div>
            <Box>
              <Typography className="headerTitle">Driver-side doo</Typography>
              <Typography className="subTitle">With the door open, look on the edge of the door to find a barcode / VIN code.</Typography>
            </Box>
          </Box>
          <Box style={{ display: "flex", gap: "15px"}}>
          <div className="countDown">2</div>
            <Box>
              <Typography className="headerTitle">Driver-side front window</Typography>
              <Typography className="subTitle">Stand outside the car and look through the windshielf at the bottom corner of your window.</Typography>
            </Box>
          </Box>
          <Box style={{ display: "flex", gap: "15px"}}>
          <div className="countDown">3</div>
            <Box>
              <Typography className="headerTitle">Documentation</Typography>
              <Typography className="subTitle">Check your car title, registration, or insurance documents.</Typography>
            </Box>
          </Box>
        </Box>

          <Box>
             <Button className="continueBtn" data-test-id="saveStep1" onClick={() => this.handleVerifyVIN()}>Continue</Button>
          </Box>
      </Stepper1>
    )
  }

  step2 = () => {
    return (

      <Stepper2>
        <Grid container spacing={5}>
          <Grid item md={8}>

            {this.stepperHeader()}
            {this.progressBarDiv()}
            {this.handleBackLink()}
            {this.handleHeaderText("About Your Car")}

            <Box style={{ display: "flex" }}>
              <Typography className="descriptionText">Car description </Typography>
              <HelpOutlineIcon />
            </Box>

            <TextareaAutosize
              value={this.state.carDescription}
              data-test-id="carDes"
              onChange={this.handleChangeForCarDescriptionStep2}
              className="textArea" placeholder="Tell guests what makes your car unique and why they’ll love driving it. New listings with descriptions of least 100 words are up to 3x more likely to get booked." />

            <Typography className="carFeatures">Car Features</Typography>

            <Box>
              <Grid container>
                {this.state.carFeatures.map((item) =>

                  <Grid key={item.id} item md={3} sm={6} xs={12} spacing={4}>
                    <Box style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      borderRadius: '8px',
                      border: '1px solid transparent',
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                      padding: "5px",
                      margin: "10px 30px 10px 0"
                    }}>
                      <Box>
                        <img src={item.icon} alt={item.label} />
                      </Box>
                      <Typography className="featuresText">{item.label}</Typography>
                      <Box>
                        <Checkbox
                          data-test-id="featuresBox"
                          checked={item.check}
                          onChange={() => this.handleCheckboxChange(item.id)}
                          color="primary" />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            {this.handleBackAndNextBtn()}
          </Grid>
          <Grid item md={4}>
            <Box style={{ background: "#E3EDFD", padding: 20, borderRadius: 12 }}>
              <Box style={{ border: "1px solid #EAECF0", background: "white", borderRadius: 12, padding: "16px 14px" }}>
                <Typography className="carInfoText" >Car Info</Typography>
                <Divider style={{ margin: "10px 0" }} />

                <Box>
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                      {this.state.editCarName
                        ?
                        <TextField data-test-id="carName" required onChange={this.handleChangeForCarName} value={this.state.carName} />
                        :
                        <Typography className="carInfoText" >{this.state.carName}</Typography>
                      }
                      
                     
                      <Typography className="vinCarText"> VIN: #{this.state.carVIN}</Typography>
                    </Box>
                    <img data-test-id="editCarName" onClick={this.editableCarName} style={{ height: 24, width: 24, cursor: "pointer" }} src={editIcon} />
                  </Box>
                  <Divider style={{ margin: "10px 0" }} />
                </Box>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography className="carInfoText" >Odometer</Typography>
                  <Typography className="selectText">Select</Typography>
                </Box>
                <Divider style={{ margin: "10px 0" }} />

                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography className="carInfoText" >Transmission</Typography>
                  <Typography className="selectText">Select</Typography>
                </Box>
                <Divider style={{ margin: "10px 0" }} />

                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography className="carInfoText" >Trim (optional)</Typography>
                  <Typography className="selectText">Select</Typography>
                </Box>
                <Divider style={{ margin: "10px 0" }} />

                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography className="carInfoText" >Style (optional)</Typography>
                  <Typography className="selectText">Select</Typography>
                </Box>
                <Divider style={{ margin: "10px 0" }} />
              </Box>

              <Box style={{margin : "10px 0"}}>
                <Typography className="carInfoText">Where is your car located?</Typography>
                <OutlinedInput placeholder="640 S Curson Ave, Los Angeles, CA..." className="locationField" fullWidth />
              </Box>

              <Box style={{ maxHeight: 180}}>

                  <img src={carLocationIcon} style={{width : "100%",border: 'none',maxHeight: 180, borderRadius: 12}}  />
              </Box>
            </Box>

          </Grid>
        </Grid>
      </Stepper2>
    )
  }

  step3 = () => {
     return (
        <Stepper3>
           
         <Grid container spacing={3}>
           <Grid item md={8}>

             {this.stepperHeader()}
             {this.progressBarDiv()}

           </Grid>
         </Grid>
           {this.handleBackLink()}
           {this.handleHeaderText("Listing Set-up")}

           <Box>
             <Typography className="enterVINLabelStep3"> Enter VIN</Typography>
             <OutlinedInput
              className="vinBoxStep3"
              value={this.state.carVIN}
              disabled
              readOnly
              />
          </Box>
          <br />

          <Box>
           <Grid container spacing={2}>
             <Grid item md={4} sm={6} xs={12}>
               <Box style={{ border: "1px solid #EAECF0" }}>

                {this.handleSameBoxStep3("Advance Notice")}
                 {this.advanceNoticeData.map((itemAdvanceNotice) =>
                   <div>
                     <Box style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                       <Typography>{itemAdvanceNotice.label} {itemAdvanceNotice.isRecommand && <span style={{ color: '#0F73EE' }}>(Recommended)</span>} </Typography>
                       <Radio
                         value={itemAdvanceNotice.id}
                         onChange={this.handleChangeforAdvancedNotice}
                         checked={itemAdvanceNotice.id === this.state.advancedNoticeId}
                         name={itemAdvanceNotice.name}
                         color="primary"
                       />
                     </Box>

                     <Divider />
                   </div>
                 )}
               </Box>
             </Grid>
             <Grid item md={4} sm={6} xs={12} >
               <Box style={{ border: "1px solid #EAECF0" }}>
                {this.handleSameBoxStep3("Minium trip duration")}
                 {this.minimumTripDuration.map((itemMinimumTrip) =>
                   <div>
                     <Box style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                       <Typography>{itemMinimumTrip.label} {itemMinimumTrip.isRecommand && <span style={{ color: '#0F73EE' }}>(Recommended)</span>}</Typography>
                       <Radio
                         name={itemMinimumTrip.name}
                         color="primary"
                         value={itemMinimumTrip.id}
                         onChange={this.handleChangeforMinimumTrip}
                         checked={itemMinimumTrip.id === this.state.minimumTripId}
                       />
                     </Box>

                     <Divider />
                   </div>
                 )}
               </Box>
             </Grid>
             <Grid item md={4} sm={6} xs={12} >
               <Box style={{ border: "1px solid #EAECF0" }}>

                 {this.handleSameBoxStep3("Maximum trip duration")}
                 {this.maximumTripDuration.map((itemMaximuTrip) =>
                   itemMaximuTrip.label !== "" && <div>
                     <Box style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                       <Typography>{itemMaximuTrip.label} {itemMaximuTrip.isRecommand && <span style={{ color: '#0F73EE' }}>(Recommended)</span>}</Typography>
                       <Radio 
                          name={itemMaximuTrip.name} 
                          value={itemMaximuTrip.id}
                         onChange={this.handleChangeforMaximumTrip}
                         checked={itemMaximuTrip.id === this.state.maximumTripId}
                          color="primary" />
                     </Box>

                     <Divider />
                   </div>
                 )}
               </Box>
             </Grid>
           </Grid>
          </Box>

           {this.handleBackAndNextBtn()}
        </Stepper3>
     )
  }


  step4 = () => {
    return (

      <Stepper4>

        <Grid container spacing={3}>
          <Grid item md={8}>

            {this.stepperHeader()}
            {this.progressBarDiv()}


            {this.handleBackLink()}
            {this.handleHeaderText("Car Photos")}

            <Typography className="getReadyText"> Get Ready </Typography>
            <Typography className="getReadySubText">We will help you take the best photos to make your listing stand out to guests. </Typography>

            {this.state.uplodedCarPhotosURL.length === 0 && <Box style={{ width: this.state.addPhotos ? "350px" : "auto" }} className="boxPhotos1">
              {this.state.addPhotos && <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                <img src={imageIcons} alt="img" />
                <Button data-test-id="addPhotos" onClick={() => this.handleToggleAddPhostosBtn()} variant="outlined" style={{ borderRadius: 20, textTransform: "none", margin: "10px 0", color: "#3173E1" }}> Add Photos</Button>
              </Box>}

              {!this.state.addPhotos && <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                <Typography>Drag and drop or  <span style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer", fontWeight: 600 }} data-test-id="uplodPhotos" onClick={() => this.handleRefCall()}> browse </span> files</Typography>

                <input type="file" data-test-id={"photosInput"} onChange={this.handleCarPhotosChange} ref={this.fileRef} multiple style={{ display: "none" }} />
              </Box>}
            </Box>}
            {!this.state.addPhotos && <Typography className="getReadySubText"><li> Images ( .jpg,.gif, .png, up to 10 MB, no more than 40000 px in any dimension) </li></Typography>}

            {this.state.uplodedCarPhotosURL.length > 0 && <Box style={{ margin: "25px 0" }}>
              <Grid container spacing={3}>
                {this.state.uplodedCarPhotosURL.map((url, index) => (
                  <Grid item md={4} sm={6} xs={12} style={{ position: "relative" }}>
                    <img data-test-id="deleteUploadedPhoto" onClick={() => this.handleDeleteUploadedPhotos(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
                    <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
                  </Grid>
                ))}
              </Grid>

              <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
                <Typography> Add More <span data-test-id="addMoreText" onClick={() => this.handleRefCall()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
              <input type="file" data-test-id={"addMorePhotos"} onChange={this.handleCarPhotosChange} ref={this.fileRef} multiple style={{ display: "none" }} />

            </Box>}
            {this.handleBackAndNextBtn()}
          </Grid>
        </Grid>
      </Stepper4>
    )
  }

  step5 = () => {
    return (

      <Stepper5>

        <Grid container spacing={3}>
          <Grid item md={8}>

            {this.stepperHeader()}
            {this.progressBarDiv()}


            {this.handleBackLink()}
            {this.handleHeaderText("Link Payouts")}
            <Box>
              <img src={payoutIcon} alt="src" />
            </Box>

            <Typography className="setUpText">Set up Stripe Account</Typography>

            <p>To receive your earnings, you need to set up an account with Stripe, our payment provider.</p>
            <p>In the next step, Stripe will verify your identity and account information.</p>
            <p> Once your account is verified, you’ll provide your direct deposit information</p>

            <Box style={{ display: "flex", gap: 20 }}>
              <Button className="saveBtn" data-test-id="saveAndFinish" onClick={() => this.addCar()}>Save & Finish Later</Button>
              <Button className="payBtn">Link stripe payouts</Button>

            </Box>
          </Grid>
        </Grid>
      </Stepper5>
    )
  }

  step6 = () => {
    return(
      <Stepper6>
        <Grid container spacing={3}>
          <Grid item md={8}>

            {this.stepperHeader()}
            {this.progressBarDiv()}

            <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
              <img src={congratsIcon} style={{ height: 100, width: 80, margin: "auto" }} alt="congrats" />

              <Typography className="congratsCarText">Congratulations</Typography>
              <Box style={{ padding: "10px 0" }}>
                <Typography className="congratsCarSubText">You are all set to go for your trip. If you have any concern or questions,
                </Typography>
                <Typography className="congratsCarSubText">please do not hesitate to reach out.</Typography>
              </Box>


              <Button
                data-test-id="navToBack" 
                onClick={this.navigateToListPage} 
                variant="contained"
                style={{
                  margin: "20px auto",
                  borderRadius: 20, color: "white",
                  background: "#3173E1",
                  textTransform: "none",
                  fontWeight: 600,
                  width: "180px"
                }}
              >
                Start Your Trip
              </Button>
            </div>
          </Grid>
        </Grid>
      </Stepper6>
    )
  }

  stepperHeader = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        {this.stepperInfo.map((label) => (
          <div>
            <Box className={this.handleStepperCSS(label.activeKey, this.state.activeStep)} key={label.id}>
              <p style={{ textAlign: "center", margin: "25% 0" }}>{label.id}</p>
            </Box>
            <p className="stepperTextAddCar">{label.label}</p>
          </div>
        ))}
      </div>
    )
  }

  progressBarDiv = () => {
    return (
      <Box style={{ margin: "30px 0" }}>
        <LinearProgress className="progressBar" value={Math.floor((this.state.activeStep / 6) * 100)} variant="determinate" />
      </Box>
    )
  }


  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />

        <AddCarMainWrapper>

          {this.handleAllStepper(this.state.activeStep)}

        </AddCarMainWrapper>

        <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default AddCar;

const AddCarMainWrapper = styled(Box)({
  maxWidth: "1220px",
  flexWrap: "wrap",
  margin: "40px auto",
  display: "flex",
  flexDirection: "column",

  "&  .activestepperIcons": {
    width: "40px",
    background: "#3173E1",
    color:"white",
    borderRadius: "50%",
    border: "1px solid #3173E1",
    height: "40px",
    margin: "auto"
  },
  "&  .currentstepperIcons": {
    width: "40px",
    background: "#E3EDFD",
    color:"#3173E1",
    height: "40px",
    borderRadius: "50%",
    border: "1px solid #3173E1",
    margin: "auto"
  },
  "& .stepperTextAddCar" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'center'
},
"&  .stepperIcons": {
  width: "40px",
  height: "40px",
  background: "white",
  borderRadius: "50%",
  border: "1px solid #3173E1",
  margin: "auto"
},
  "& .progressBar": {
    height: "10px",
    borderRadius: "10px",
    backgroundColor:"#E3EDFD",
  },
  "& .progressBar .MuiLinearProgress-barColorPrimary": {
    backgroundColor:"#3173E1"
  }, 
  "& .nextBtn" : {
    width: '208px',
    gap: '8px',
    backgroundColor: '#3173E1',
    height: '48px',
    borderRadius: '12px',
    color: 'white',
    margin: "15px 0",
    textTransform: "none",
    padding: '14px 16px',
    fontWeight: "bold",
  },
  "& .backBtn" : {
    borderRadius: '12px',
    gap: '8px',
    height: '48px',
    color: '#475467',
    fontWeight: "bold",
    border: "1px solid #D0D5DD",
    width: '208px',
    backgroundColor: 'white',
    padding: '14px 16px',
    margin: "15px 0",
    textTransform: "none"
  },
  "& .headerText": {
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '30px',
    lineHeight: '38px',
    fontFamily: 'Plus Jakarta Sans',
    margin: "25px 0"
  },
  "& .carInfoText" : {
    fontSize: "16px",
    fontWeight: "600",
    color:"#22395D",
    fontFamily: 'Plus Jakarta Sans',
  },
  "& .vinCarText" : {
    fontSize: "12px",
    fontWeight: "500",
    color:"#B0B0B0",
    fontFamily: 'Plus Jakarta Sans',
  },
  "& .selectText" : {
    fontSize: "14px",
    fontWeight: "500",
    color:"#0F73EE",
    fontFamily: 'Plus Jakarta Sans',
  },
  "& .locationField" : {
    background: "white",
    height: "45px",
    borderRadius: "12px",
    margin: "5px 0"
  }
})

const Stepper1 = styled(Box)({
  "& .vinBox" : {
    width: '669px',
    height: '44px',
    gap: '8px',
    borderRadius: '12px',
    opacity: '0px'
  },
  "& .enterVINLabel" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    lineHeight: '30px',
    textAlign: 'left',
    margin: "10px 0"
  },
  "& .countDown" : {
    height: '18px',
    backgroundColor: '#3173E1',
    color: 'white',
    padding: '4px 10px',
    borderRadius: '50%',
    width: '6px',
    textAlign: 'center',
    alignContent: 'center',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 400
  }, 
  "& .headerTitle" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22.68px',
    textAlign: 'left'
  },
  "& .subTitle" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    lineHeight: '19.6px',
    textAlign: 'left',
    margin: "5px 0"
  },
  "& .continueBtn" : {
    width: '208px',
    height: '48px',
    padding: '14px 16px',
    gap: '8px',
    borderRadius: '12px',
    opacity: '0px',
    backgroundColor: '#3173E1',
    color: 'white',
    margin: "15px 0",
    fontWeight: "bold",
    textTransform: "none"
  }
})

const Stepper5 = styled(Box)({
  "& .setUpText" : {
    margin: "15px 0",
    fontSize: '20px',
  fontWeight: 600,
  lineHeight: '30px',
  textAlign: 'left'
  }, 
  "& .payBtn" : {
    width: '208px',
    gap: '8px',
    padding: '14px 16px',
    height: '48px',
    borderRadius: '12px',
    color: 'white',
    margin: "15px 0",
    backgroundColor: '#3173E1',
    fontWeight: "bold",
    textTransform: "none"
  },
  "& .saveBtn" : {
    gap: '8px',
    borderRadius: '12px',
    padding: '14px 16px',
    height: '48px',
    color: '#475467',
    border: "1px solid #D0D5DD",
    width: '208px',
    backgroundColor: 'white',
    fontWeight: "bold",
    margin: "15px 0",
    textTransform: "none"
  },

  
})

const Stepper2 = styled(Box)({
  "& .descriptionText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20.16px',
    textAlign: 'left'
  }, 
  "& .textArea" : {
     width: "50%",
     height: "150px !important",
     margin: "10px 0",
     fontFamily: 'Plus Jakarta Sans',
     fontSize: "14px",
     padding: "20px",
     borderRadius: '12px',
    border:"1px solid #D0D5DD"
  }, 
  "& .carFeatures" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    textAlign: 'left'
  }, 
  "& .featuresText" : {
    width: 100, 
    maxWidth: 100, 
    padding: "0 10px",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.12px',
    letterSpacing: '-0.03em',
    textAlign: 'left'
  },
  "& .carInfoText" : {
    fontSize: "16px",
    fontWeight: "600",
    color:"#22395D",
    fontFamily: 'Plus Jakarta Sans',
  }
})

const Stepper3 = styled(Box)({
  "& .enterVINLabelStep3" : {
    textAlign: 'left',
    lineHeight: '30px',
    fontSize: '16px',
    fontFamily: 'Plus Jakarta Sans',
    margin: "10px 0"
  },
  "& .vinBoxStep3" : {
    height: '44px',
    width: '669px',
    borderRadius: '12px',
    gap: '8px',
    opacity: '0px'
  },
  "& .descriptionText3" : {
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Plus Jakarta Sans',
    textAlign: 'left'
  }, 
})

const Stepper4 = styled(Box)({
  "& .getReadyText": {
    fontWeight: 600,
    fontSize: "20px",
    color: "#23395D"
  },
  "& .getReadySubText": {
    fontWeight: 500,
    fontSize: "20px",
    color: "#475467"
  }, 
  "& .boxPhotos1" : {
    height: "300px",
    border: "1px dashed #3173E1",
    borderRadius: "12px",
    display: "flex",
    margin: "20px 0"
  }, 
})

const Stepper6 = styled(Box)({
  "& .congratsCarText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontWeight: 600,
    textAlign: "center",
    color: "#23395D",
    margin: "10px 0"
  },
  "& .congratsCarSubText" : {
    fontSize: '16px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 400,
    color: "#475467",
    textAlign: "center",
  }
})

// Customizable Area End