// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCalling } from "../../../components/src/asset";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { house1, house2, house3, house4, house5, house6 } from "./assets";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface HomeType {
  id: number;
  icon: string; 
  label: string;
  name: string;
}


interface S {
  // Customizable Area Start  
  activeHomeStepperStep : number
  addHomePhotos: boolean,
  homePhotos: File[],
  uplodedHomePhotosURL: string[],
  noOfGuest: number,
  noOfBeds: number,
  noOfBedrooms: number,
  noOfBathrooms: number,
  noOfParkingSpace: number,
  homeTypesData : HomeType[],
  selectedHomeId: string,
  homeDescription: string,
  homeVIN : string,
  homeSpaceType : string,
  homeFeatures: any[],
  catogoryIdHome: number | string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddHomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputFileRef: React.RefObject<HTMLInputElement>
  getHomeSubCatogoryListingApiCallId : string = ""
  createHomeApiCallId: string =  ""
  getCatogoriesIdApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        activeHomeStepperStep: 1,
        addHomePhotos: true,
        homePhotos: [],
        uplodedHomePhotosURL: [],
        noOfGuest: 0,
        noOfBeds: 0,
        noOfBedrooms: 0,
        noOfBathrooms: 0,
        noOfParkingSpace: 0,
        homeTypesData: [],
        selectedHomeId: "",
        homeDescription: "",
        homeVIN: "",
        homeSpaceType: "A private room",
        homeFeatures: [],
        catogoryIdHome: ""
    };
    this.inputFileRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if(apiRequestCallId === this.getHomeSubCatogoryListingApiCallId) {
           this.handleHomeListingResponse(responseJson)
        } else if( apiRequestCallId === this.createHomeApiCallId) {
           this.handleCreateHomeResponse(responseJson)
        } else if( apiRequestCallId === this.getCatogoriesIdApiCallId) {
          this.handleResponseForCatogoriesId(responseJson)
       }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getCatogories()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.catogoryIdHome !== this.state.catogoryIdHome) {
      this.getHomeSubListing()
   }
  }

  handleResponseForCatogoriesId =(responseJson: any) => {
    if(responseJson && responseJson.length > 0) {
      const homeId = responseJson.find((item: any) => item.name === "Home").id
      this.setState({ catogoryIdHome: homeId})
    }
  } 

  handleHomeListingResponse = ( responseJson : any) => {
    if(responseJson && responseJson.data) {
      const tempData = responseJson.data.map((arrayValue : any) => {
        return {
          id: arrayValue.attributes.id,
          icon: this.switchIcons(arrayValue.attributes.name), 
          label: arrayValue.attributes.name,
          name: "home_type"   
        }
      })

       this.setState({ homeTypesData: tempData, selectedHomeId: responseJson.data[0].id})

    }
  }

  switchIcons = (keyName: string) => {
    switch(keyName) {
      case "Townhouse":
        return house1
      case"Residental House":
        return house2
      case "Apartment":
        return house3
      case "Villa":
        return house4
      case "Guest House":
        return house5
      case "Guest Suite":
        return house6
    }
  }

  handleCreateHomeResponse = (responseJson : any) => {
    if(responseJson && responseJson.catalogue) {
       this.handleNextHomeStep();
    } else {
       toast.error("Something went wrong. Please Try again later")
    }
  }

  handleSelectedHome = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedHomeId: event.target.value })
  }

  handleSelectedHomeSpaceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ homeSpaceType: event.target.value })
  }
  
  handleChangeOnHomeFeatures = (event: React.ChangeEvent<HTMLInputElement>, itemName: string) => {
    const { homeFeatures } = this.state;
    if (event.target.checked) {
      this.setState({ homeFeatures: [...homeFeatures, itemName] });
    } else {
      this.setState({
        homeFeatures: homeFeatures.filter(inputValue => inputValue !== itemName),
      });
    }
  };

  addHomeStepperSteps = [
    { id : 1, label: "Select Home", activeKey : 1},
    { id : 2, label: "Home Details", activeKey : 2},
    { id : 3, label: "Guests Information", activeKey : 3},
    { id : 4, label: "Home Photos", activeKey : 4},
    { id : 5, label: "Link Payouts", activeKey : 5},
    { id : 6, label: "Success", activeKey : 6}
  ]

  guestType = [
    { id : 1, label: "An entire place", name: "guest_type", value :  "An entire place"},
    { id : 2, label: "A private room", name: "guest_type", value :  "A private room"},
    { id : 3, label: "A shared room", name: "guest_type", value :  "A shared room"}
  ]

  handleAddHomeStepperCSS = (activeKeyFromArray:number, activeRealState: number) => {
    if(activeKeyFromArray === activeRealState) {
       return "AddHomeCurrentStepper"
    } else if( activeKeyFromArray < activeRealState) {
      return "AddHomeActiveStepper"
    } else {
      return "AddHomeStepper"
    }
  }

  handleNextHomeStep = () => {   
    this.setState({ activeHomeStepperStep : this.state.activeHomeStepperStep+1})
  }

  handleChangeForHomeVinStep2 = (event : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ homeVIN : event.target.value})
  }

  handleChangeForHomeDescriptionStep2 = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ homeDescription : event.target.value})
  }

  handlePreviousHomeStep = () => {
    this.setState({ activeHomeStepperStep : this.state.activeHomeStepperStep-1})
  }

  handleHomeToggleAddPhostosBtn = () => {
    this.setState({ addHomePhotos : false})
  }

  handleHomePhotosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        homePhotos: [...prevState.homePhotos, ...newFiles],
        uplodedHomePhotosURL: [...prevState.uplodedHomePhotosURL, ...fileURLs]
      }));
    }

  };

  handleDeleteUploadedPhotos = (index: number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.homePhotos];
      const updatedPreviews = [...prevState.uplodedHomePhotosURL];

      updatedFiles.splice(index, 1);
      updatedPreviews.splice(index, 1);

      return {
        homePhotos: updatedFiles,
        uplodedHomePhotosURL: updatedPreviews,
      };
    });
  };

  handleInputRefCall = () => {
    if (this.inputFileRef.current) {
      this.inputFileRef.current.click();
    }
  }

  handleIncreseCount = (currentValue : number, stateName: keyof S) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateName]: currentValue + 1,
    }));

  }

  handleDecreaseCount = (currentValue : number, stateName: keyof S) => {

    if(currentValue === 0) {
      return false
    }
    this.setState((prevState) => ({
      ...prevState,
      [stateName]: currentValue - 1,
    }));

  }

  navigateToListingPage = () => {
    this.props.navigation.navigate("HostDashboard")
  }

  getCatogories = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    
    this.getCatogoriesIdApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.getCatogoriesApiEndPoint,
      method: configJSON.apiMethodTypeGet
    })
  }

  getHomeSubListing = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    
    this.getHomeSubCatogoryListingApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getHomeSubListingApiEndPoint}/${this.state.catogoryIdHome}`,
      method: configJSON.apiMethodTypeGet
    })
  }

  addHome = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    const formData = new FormData();
    formData.append("catalogue[category_id]", this.state.catogoryIdHome.toString())
    formData.append("catalogue[sub_category_id]", this.state.selectedHomeId)
    formData.append("catalogue[features][home_title]", "Beautiful Mansion")
    formData.append("catalogue[features][Guests]", this.state.noOfGuest.toString())
    formData.append("catalogue[features][beds]", this.state.noOfBeds.toString())
    formData.append("catalogue[features][bathrooms]", `${this.state.noOfBathrooms}`)
    formData.append("catalogue[features][Bedrooms]", `${this.state.noOfBedrooms}`)
    formData.append("catalogue[features][Parking Spaces]", `${this.state.noOfParkingSpace}`)
    formData.append("catalogue[features][home_description]", this.state.homeDescription)
    this.state.homeFeatures.forEach(value => {
      formData.append("catalogue[features][home_amenities][]", value);
    });
    formData.append("catalogue[features][space_type]", this.state.homeSpaceType)
    formData.append("catalogue[name]", "Home")
    formData.append("type", "Home")
    this.state.homePhotos.forEach(value => {
      formData.append("catalogue[images][]", value);
    });
    formData.append("address[country]", "India")
    formData.append("address[latitude]", "22.719568")
    formData.append("address[longitude]", "75.857727")
    formData.append("address[city]", "bhopal")
    formData.append("address[pincode]", "462010")
    formData.append("address[address]", "Vijay nagar")
    formData.append("address[address_type]", "pickup_location")
    formData.append("catalogue[status]","active")

   this.createHomeApiCallId = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypePost,
    endPoint: configJSON.createHomeApiEndpoint,
    body: formData
   })
  }


  // Customizable Area End
}

// Customizable Area End