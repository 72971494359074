// Customizable Area Start
import React from "react";

import {
  Box,
  styled,
  InputAdornment,
  Typography,
  TextField
} from "@material-ui/core";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Footer from "../../../components/src/Footer.web";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import ExtraAddOnController, {
  Props,
  configJSON
} from "./ExtraAddOnController.web";

export default class TripPreference extends ExtraAddOnController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderTripPreference = () => {
    return (
      <Box className="LocationSection">
        <Box className="backSection" onClick={this.handleBackButton} data-test-id="back-btn">
          <ArrowBackIosIcon className="backIcon" />
          <Typography className="backText">Back</Typography>
        </Box>
        <Box className="backSectionIcon">
          <ArrowBackIosIcon className="backIcon" />
        </Box>
        <Box className="locationSectionBox">
          <Typography className="loactionText">
            {configJSON.tripHeadText}
          </Typography>
          <Box className="tripSectionBox">
            <Box className="tripBlockSection">
              <Box className="tripSection">
                <Typography className="tripSectionHead">
                  Minimum trip duration
                </Typography>
                <HelpOutlineOutlinedIcon />
              </Box>
              <TextField
                className="tripSectionField"
                placeholder="1 Day (Recommended)"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="editBtn">
                      Edit
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box className="tripBlockSection">
              <Box className="tripSection">
                <Typography className="tripSectionHead">
                  Maximum trip
                </Typography>
                <HelpOutlineOutlinedIcon />
              </Box>
              <TextField
                className="tripSectionField"
                placeholder="Any (Recommended)"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="editBtn">
                      Edit
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box className="tripBlockSection">
              <Box className="tripSection">
                <Typography className="tripSectionHead">
                  Advance notice
                </Typography>
                <HelpOutlineOutlinedIcon />
              </Box>
              <TextField
                className="tripSectionField"
                placeholder="2 hours (Recommended)"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="editBtn">
                      Edit
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box className="tripBlockSection">
              <Box className="tripSection">
                <Typography className="tripSectionHead">
                  Weekend duration trips
                </Typography>
                <HelpOutlineOutlinedIcon />
              </Box>
              <TextField
                className="tripSectionField"
                placeholder="2-day min for weekend trips"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="editBtn">
                      Edit
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box className="tripBlockSection">
              <Box className="tripSection">
                <Typography className="tripSectionHead">
                  Instant acceptance
                </Typography>
                <HelpOutlineOutlinedIcon />
              </Box>
              <TextField
                className="tripSectionField"
                placeholder="Allow instant bookings"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="editBtn">
                      Edit
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  render() {
    // Customizable Area Start

    return (
      <CustomBox>
        <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
        <Box className="mainBox">{this.renderTripPreference()}</Box>
        <Footer />
      </CustomBox>
    );
    // Customizable Area End
  }
}
const CustomBox = styled(Box)({
  "& .mainBox": {
    width: "100%",
    justifyContent: "center",
    display: "flex"
  },
  "& .LocationSection": {
    paddingTop: "48px",
    width: "80%",
    "@media(max-width:600px)": {
      width: "90%"
    }
  },
  "& .backSectionIcon": {
    display: "none",
    "@media(max-width: 600px)": {
      display: "block"
    }
  },
  "& .backSection": {
    display: "flex",
    alignItems: "center",
    color: "#667085",
    gap: "6px",
    "@media(max-width: 600px)": {
      display: "none"
    }
  },
  "& .backText": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px"
  },
  "& .backIcon": {
    width: "20px",
    height: "20px"
  },
  "& .locationSectionBox": {
    flexDirection: "column",
    paddingTop: "32px",
    width: "72%",
    display: "flex",
    gap: "32px",
    "@media(max-width:600px)": {
      width: "100%"
    }
  },
  "& .tripSectionBox": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    "@media(max-width:600px)": {
      width: "80%"
    }
  },
  "& .tripSectionHead": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#23395D"
  },
  "& .loactionText": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "38px",
    textAlign: "left",
    color: "#101828",
    "@media(max-width:600px)": {
      textAlign: "center",
      marginTop: "-65px"
    }
  },

  "& .tripSection": {
    display: "flex",
    alignItems: "center",
    color: "#23395D",
    gap: "5px"
  },
  "& .tripSectionField": {
    border: "1px solid #D0D5DD",
    borderRadius: "12px",
    width: "100%",
    padding: "20px"
  },
  "& .tripBlockSection": {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  "& .cursor": {
    cursor: "pointer"
  },
  "& .editBtn": {
    cursor: "pointer",
    "& .MuiTypography-colorTextSecondary": {
      color: "#0F73EE",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontWeight: 500
    }
  }
});

// Customizable Area End
