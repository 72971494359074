// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography, Button,
    Dialog,
    DialogTitle,
    DialogContent, Divider, FormControlLabel, Radio,
    TextField, RadioGroup, FormControl
} from "@material-ui/core"
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExtraAddOnController, {
    Props, configJSON, CatalogueItem
} from "./ExtraAddOnController.web";
import Footer from "../../../components/src/Footer.web";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
const images = require("./assets")

export default class ExtraAddOn extends ExtraAddOnController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    renderExtraAddOn = () => {
        const { extraAddOnData, setEdit } = this.state;
        return (
            <Box className="LocationSection">
                <Box className="backSection" onClick={this.handleBackButton}>
                    <ArrowBackIosIcon className="backIcon" />
                    <Typography className="backText">Back</Typography>
                </Box>
                <Box className="backSectionIcon">
                    <ArrowBackIosIcon className="backIcon" />
                </Box>
                <Box className="locationSectionBox">
                    <Box className="locationServiceBox">
                        <Box className="extraHead">
                            <Typography className="loactionText">{configJSON.extraAddOnText}</Typography>
                            <img src={images.addIcon} className="cursor" data-test-id="handleModal" onClick={this.handleModal} />

                        </Box>
                        {extraAddOnData?.map((data: CatalogueItem, index: number) => {
                            return (
                                <Box className="fuelBox">
                                    <Box>
                                        <Typography className="addressFuel">{data.title}</Typography>
                                        <Typography className="descriptionFuel">${data.price} <span className="editBtn" data-test-id="handleOpenEditModal" onClick={() => this.handleOpenEditModal(data)}>Edit</span> </Typography>
                                    </Box>
                                    <RadioButtonCheckedIcon className={data.check ? "radioActiveIcon" : "radioIcon"}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <CustomDialog open={this.state.openEditModal} data-test-id="handleCloseEditModal" onClose={this.handleCloseEditModal} PaperProps={{
                    className: "setDialogueBox"
                }}>
                    <DialogTitle className="modalHeadTextStyle">
                        <Box className="titleBox">
                            {configJSON.modalHeadText}
                            <CancelOutlinedIcon className="cursor" onClick={this.handleCloseEditModal} />
                        </Box>
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        <FormControl component="form" data-test-id="postAddExtraAddOn" onSubmit = {this.postAddExtraAddOn} className="sectionSelectionBlock">
                        <Box className="sectionBlock">
                            <Typography className="nameText">{configJSON.addOnName}</Typography>
                            <TextField className="locationField" placeholder="Prepaid fuel"
                                data-test-id="handleAddOnName"
                                onChange={this.handleAddOnName}
                                required
                                value={this.state.addOnName}
                                InputProps={{
                                    disableUnderline: true,
                                }} />
                        </Box>
                        <Box className="sectionBlock">
                            <Typography className="nameText">Cost</Typography>
                            <TextField className="locationField" placeholder="$2395"
                                data-test-id="handleSetPrice"
                                value={this.state.setPrice}
                                onChange={this.handleSetPrice}
                                required
                                InputProps={{
                                    disableUnderline: true,
                                    inputProps: { min: 0 }
                                }} />
                        </Box>
                        <Box className="sectionSelectionBlock">
                            <Typography className="nameText">Payment type</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="options"
                                    name="options"
                                    className="radioSelect"
                                    data-test-id="handleSelectTrip"
                                    onChange={this.handleSelectTrip}
                                    value={this.state.selectedOption}
                                    aria-required
                                >
                                    <FormControlLabel value="option1" control={<Radio />} label="Per Trip" />
                                    <FormControlLabel value="option2" control={<Radio />} label="Per Day" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box className="btnsSection">
                            <Button className="discardBtn" onClick={this.handleCloseEditModal}>{configJSON.discardBtn}</Button>
                            <Button className="saveBtn" type="submit" >{setEdit ? "Update": "Add"}</Button>
                        </Box>
                        </FormControl>
                    </DialogContent>
                </CustomDialog>
            </Box>
        )
    }


    render() {
        // Customizable Area Start

        return (
            <CustomBox>
                <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
                <Box className="mainBox">
                    {this.renderExtraAddOn()}

                </Box>
                <Footer />
            </CustomBox>);
        // Customizable Area End
    }
}
const CustomBox = styled(Box)({
    "& .mainBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    "& .LocationSection": {
        width: "80%",
        paddingTop: "48px",
        "@media(max-width:600px)": {
            width: "90%"
        }
    },
    "& .backSection": {
        display: 'flex',
        alignItems: "center",
        color: "#667085",
        gap: "6px",
        "@media(max-width: 600px)": {
            display: "none"
        }
    },
    "& .backSectionIcon": {
        display: "none",
        "@media(max-width: 600px)": {
            display: "block"
        }
    },
    "& .backIcon": {
        width: "20px",
        height: "20px"
    },
    "& .backText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px"

    },
    "& .locationSectionBox": {
        paddingTop: "32px",
        width: "72%",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        "@media(max-width:600px)": {
            width: "100%",
        }
    },
    "& .loactionText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "30px",
        fontWeight: 600,
        lineHeight: "38px",
        textAlign: "left",
        color: "#101828",
        "@media(max-width:600px)": {
            textAlign: "center",
            marginTop: "-65px"
        }
    },
    "& .locationServiceBox": {
        display: 'flex',
        flexDirection: "column",
        gap: "16px"
    },
    "& .extraHead": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .fuelBox": {
        border: "1px solid #D0D5DD",
        borderRadius: "12px",
        padding: "35px 24px 35px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .addressFuel": {
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: "Montserrat",
        lineHeight: "21.94px",
        color: "#23395D",
    },
    "& .descriptionFuel": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17.64px",
        color: "#94A3B8"
    },
    "& .radioIcon": {
        cursor: "pointer",
        color: '#667085'
    },
    "& .radioActiveIcon": {
        color: "#0F73EE",
        cursor: "pointer"
    },
    "& .cursor": {
        cursor: "pointer"
    },
    "& .editBtn": {
        color: "#0F73EE",
        textDecoration: "underline",
        cursor: "pointer"
    },

})
const CustomDialog = styled(Dialog)({
    "& .nameText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#23395D"
    },
    "& .modalHeadTextStyle": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "28px",
        color: "#23395D",
    },
    "& .setDialogueBox": {
        minWidth: "482px",
        borderRadius: "16px",
        padding: "0px 20px 32px 20px"
    },
    "& .titleBox": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        paddingBottom: "20px"
    },
    "& .locationField": {
        padding: "10px 16px 10px 16px",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        color: "#475467",
        width: "90%"
    },
    "& .sectionBlock": {
        display: 'flex',
        gap: "4px",
        flexDirection: "column"
    },
    "& .sectionSelectionBlock": {
        display: 'flex',
        gap: "14px",
        flexDirection: "column"
    },
    "& .btnsSection": {
        display: 'flex',
        gap: "10px",
    },
    "& .discardBtn": {
        border: "1px solid #979797",
        color: "#7B7B7B",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize",
        width: "50%",
        height: "52px",
        borderRadius: "12px",
        cursor:'pointer'
    },
    "& .saveBtn": {
        color: "#FFFFFF",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize",
        backgroundColor: "#3173E1",
        width: "50%",
        height: "52px",
        borderRadius: "12px",
        cursor:'pointer'
    },
    "& .radioSelect": {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        gap: "35px",
        "& .MuiTypography-body1": {
            fontFamily: "Plus Jakarta Sans",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "28px",
            color: "#23395D",
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: '#3273E1'
        }
    }

})

// Customizable Area End