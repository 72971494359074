import React from 'react';
import { Box, Typography, Divider, styled } from '@material-ui/core';
import { DateRange, DateRangePicker } from 'react-date-range';
import CustomStepper from './CustomStepper.web';


const PricingCard: React.FC = () => {

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    disabled : true
  }

  const steps = [
    {
      label: 'Rental Price',
      details: '$1,400 / Night',
      subDetails: "(x3 Night)",
      amount: '$0',
    },
    {
      label: 'Stay Fees',
      details: 'Extended Trip Discount',
      subDetails: "(5%)",
      amount: '$0',
    },
    {
      label: 'House Taxes',
      details: 'Refunded by Oct 28',
      subDetails: "",
      amount: '$0',
    },
  ];


  return (
    <Wrapper>
       <Box className='bgTransparent' style={{ padding: 0, margin: "10px 0"}}>
         <DateRange
            editableDateInputs={false}
            showMonthArrow
            ranges={[selectionRange]}
         />

      </Box>
      <Box className='bgTransparent'>
         <Typography className='headerText'>Pricing</Typography>
         <Divider style={{ margin: "10px 0"}} />
         <Typography className='earningText'>Your Earnings</Typography>
         <Box className='earningBox'>
           $0
         </Box>
         <Box className='pricingBox'>
           <CustomStepper steps={steps}/>
         </Box>
         <Divider style={{ marginBottom: "10px"}}  />
         <Box className='receiveBox'>
            <Typography className='title1'>Total Received</Typography>
            <Box className='priceBox'>
              $0
           </Box>
         </Box>
      </Box>
      <Box className='bgTransparent' style={{ margin : "10px 0"}}>
         <Typography className='headerText'>Discounts</Typography>
         <Divider style={{ margin: "10px 0"}} />

         <Typography className='durationText'>Duration Discounts</Typography>
         <Typography className='durationSubText'>Encourage guests to book longer trips</Typography>
         <Divider style={{ margin: "10px 0"}} />

         <Box className='flexBox'>
           <Box>
             <Typography className='title1'>3+ day discount</Typography>
             <Typography className='title2'>Recommended: 10%</Typography>
           </Box>
           <Box className='priceBox'>
              5%
           </Box>

         </Box>

         <Box className='flexBox'>
           <Box>
             <Typography className='title1'>7+ day discount</Typography>
             <Typography className='title2'>Recommended: 20%</Typography>
           </Box>
           <Box className='priceBox'>
              15%
           </Box>

         </Box>

         <Box className='flexBox'>
           <Box>
             <Typography className='title1'>30+ day discount</Typography>
             <Typography className='title2'>Recommended: 30%</Typography>
           </Box>
           <Box className='priceBox'>
              25%
           </Box>

         </Box>

      </Box>
      
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
    padding: "20px",
    background: "#EAECF0",
    borderRadius: 12,
    "& .headerText" : {
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: 'Plus Jakarta Sans',
      color: '#23395D',
    },
    "& .earningText": {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Plus Jakarta Sans',
      color: '#667085'
    },
    "& .bgTransparent": {
      background: "white",
      border: "1px solid #E7E7E7",
      borderRadius: 12,
      padding: "10px"
    },
    "& .earningBox": {
      background: "#F2F4F7",
      padding: "10px",
      color: '#98A2B3',
      marginTop: 5,
      borderRadius: 8
    },
    "& .durationSubText" : {
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: 'Plus Jakarta Sans',
      color: '#B0B0B0'
    },
    "& .durationText" : {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Plus Jakarta Sans',
      color: "#23395D"
    },
    "& .priceBox" : {
      background: "#EAECF0",
      padding: "10px",
      color: "#23395D",
      width: "90px",
      fontWeight: 600,
      textAlign: "center",
      borderRadius: 8
    },
    "& .title1" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 500,
      color: "#23395D"
    },
    "& .title2" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 500,
      color: "#B0B0B0"
    },
    "& .flexBox" : {
      display: "flex",
      justifyContent: "space-between", 
      alignItems: "center",
      margin: "15px 0"
    },
    "& .pricingBox .MuiStepConnector-vertical": {
      marginLeft: 8,
      padding: 0
    },
    "& .receiveBox" : {
      display : "flex", 
      alignItems: "center", 
      justifyContent: "space-between",
      marginBottom: 10
    }
})

export default PricingCard;
